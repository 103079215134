import * as React from "react";

import {
  Datagrid,
  ListButton,
  ReferenceField,
  TextField,
  useListContext,
  useRecordContext,
} from "react-admin";
import { Table, TableCell, TableBody, TableRow } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useEffect } from "react";

const LeaderbordList = () => {
  const { data, isLoading, filterValues, showFilter, hideFilter } =
    useListContext();
  const shouldShowRegionFilter =
    filterValues.leaderboardCategories === "angebote" ||
    filterValues.leaderboardCategories === "kunden" ||
    filterValues.leaderboardCategories === "rechnungen";
  const shouldShowCustomTime = filterValues.time_period === "custom";

  useEffect(() => {
    if (filterValues.leaderboardCategories === "zustandig") {
      showFilter("wcategory_id");
    } else {
      hideFilter("wcategory_id");
    }

    if (shouldShowRegionFilter) {
      showFilter("regionId");
    } else {
      hideFilter("regionId");
    }

    if (shouldShowCustomTime) {
      showFilter("start_date");
      showFilter("end_date");
    } else {
      hideFilter("start_date");
      hideFilter("end_date");
    }
  }, [
    filterValues.leaderboardCategories,
    filterValues.time_period,
    showFilter,
    hideFilter,
    shouldShowRegionFilter,
    shouldShowCustomTime,
  ]);

  if (isLoading) {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Loading...</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
  if (!isLoading && data.length > 0 && data[0].action) {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>{data[0].action}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
  if (filterValues.leaderboardCategories === "kunden") {
    return (
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" sortable={false} />
        <RankingField source="ranking" textAlign="center" sortable={false} />
        <ReferenceField
          source="id"
          reference="kundenlistes"
          label="Kunde"
          sortable={false}
        />
        {filterValues.regionId && (
          <ReferenceField
            source="regionId"
            reference="regions"
            label="Standort"
          />
        )}
        <KundeButton source="id" label="Jobs" sortable={false} />
        <TextField source="revenue" sortable={false} />
      </Datagrid>
    );
  }
  if (filterValues.leaderboardCategories === "zustandig") {
    return (
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" sortable={false} />
        <RankingField source="ranking" textAlign="center" sortable={false} />
        <ReferenceField
          source="id"
          reference="workers"
          label="Zuständig"
          sortable={false}
        />
        <ZustandigButton source="id" label="Revenue" sortable={false} />
        <TextField source="deckungsbeitrag" sortable={false} />
        <PercentageField label="%" source="percentage" sortable={false} />
      </Datagrid>
    );
  }
  if (filterValues.leaderboardCategories === "rechnungen") {
    return (
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" sortable={false} />
        <RankingField source="ranking" textAlign="center" sortable={false} />
        <ReferenceField
          source="id"
          reference="users"
          label="Abgerechnet von"
          sortable={false}
        />
        <RechnungenButton source="id" label="Revenue" sortable={false} />
        {filterValues.regionId && (
          <ReferenceField
            source="regionId"
            reference="regions"
            label="Standort"
          />
        )}
      </Datagrid>
    );
  }
  if (filterValues.leaderboardCategories === "angebote") {
    return (
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" sortable={false} />
        <RankingField source="ranking" textAlign="center" sortable={false} />
        <ReferenceField
          source="id"
          reference="users"
          label="Angebot geschrieben von"
          sortable={false}
        />
        {filterValues.regionId && (
          <ReferenceField
            source="regionId"
            reference="regions"
            label="Standort"
          />
        )}
        <TextField source="revenue" sortable={false} />
        <TextField
          source="no_of_projects"
          label="Anzahl Angebote geschrieben"
          sortable={false}
        />
        <TextField source="deckungsbeitrag_percentage" sortable={false} />
        <TextField source="umsatz_percentage" sortable={false} />
      </Datagrid>
    );
  }
  if (filterValues.leaderboardCategories === "berichte") {
    return (
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" sortable={false} />
        <RankingField source="ranking" textAlign="center" sortable={false} />
        <ReferenceField
          source="id"
          reference="users"
          label="Bericht erstellt von"
          sortable={false}
        />
        {/* <ReferenceField
          source="id"
          reference="tasks"
          label="Anzahl erstellter Berichte"
          sortable={false}
        /> */}
        <TextField
          source="tasks"
          label="Anzahl erstellter Berichte"
          sortable={false}
        />
        {filterValues.regionId && (
          <ReferenceField
            source="regionId"
            reference="regions"
            label="Standort"
          />
        )}
      </Datagrid>
    );
  }
  // if (filterValues.leaderboardCategories === "loBerichte") {
  //    return (
  //     <Datagrid bulkActionButtons={false}>
  //         <TextField source="id" sortable={false} />
  //         <ReferenceField source="id" reference="users" label="Tasker" sortable={false} />
  //         <TextField source="tasks" label="Tasks Completed" sortable={false} />
  //       </Datagrid>
  //    )
  // }
};

export default LeaderbordList;
const RankingField = ({ record, ...rest }) => {
  const recordContext = useRecordContext(record);
  return (
    <div>
      {recordContext.ranking == 1 && (
        <EmojiEventsIcon style={{ color: "gold" }} />
      )}
      {recordContext.ranking == 2 && (
        <EmojiEventsIcon style={{ color: "#C0C0C0" }} />
      )}
      {recordContext.ranking == 3 && (
        <EmojiEventsIcon style={{ color: "#b08d57  " }} />
      )}
      {recordContext.ranking > 3 && recordContext.ranking}
    </div>
  );
};
const PercentageField = ({ source }) => {
  const record = useRecordContext();
  return <span>{record[source]}%</span>;
};
const KundeButton = ({ source }) => {
  const record = useRecordContext();
  return (
    <ListButton
      resource={`projects?filter={"kundeId":"${record[source]}"}`}
      label={record.jobs}
    />
  );
};
const ZustandigButton = ({ source }) => {
  const record = useRecordContext();
  return (
    <ListButton
      resource={`projects?filter={"zustandig":"${record[source]}"}`}
      label={record.revenue}
    />
  );
};
const RechnungenButton = ({ source }) => {
  const record = useRecordContext();
  return (
    <ListButton
      resource={`projects?filter={"abgerechnet_vonId":"${record[source]}"}`}
      label={record.revenue}
    />
  );
};
