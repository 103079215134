import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Paper } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { IntranetLinks } from './IntranetLinks';
import { IntranetListItemButtons } from './IntranetLink';
import { IntranetContext } from './IntranetContext';
import ConfirmationDialog from './ConfirmationDialog';
import config from '../../config';
import AddFolderDialog from './AddFolderDialog';

const apiUrl = config.apiUrl;

export default function IntranetFolders({ id, name }) {
	const { getLinksFolderData, handleFolderDelete } = React.useContext(IntranetContext);
	const [folderLinks, setFolderLinks] = useState([]);
	const [confirmDialogToggle, setConfirmDialogToggle] = useState(false);
	const [openAddFolderDialog, setOpenAddFolderDialog] = useState(false);

	useEffect(() => {
		const linkFolderData = getLinksFolderData(id);
		setFolderLinks(linkFolderData.links);
	}, [id, getLinksFolderData]);

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition
	};

	const handleDeleteLinkFolder = async () => {
		try {
			const response = await fetch(apiUrl + '?section=intranet&action=deleteFolder&id=' + id, {
				method: 'POST',
			});
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const result = await response.json();
			handleFolderDelete(id, 'linkFolder');
			console.log('linkFolder deleted succesfully', result);
		} catch (err) {
			console.log('linkFolder not deleted');
		}
	}

	return (
		<>
			<Paper
				ref={setNodeRef}
				style={style}
				{...attributes}
				elevation={0}
				sx={{ padding: '10px 0', paddingBottom: 0, width: '100%' }}
			>
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Typography variant="p" component="p" noWrap display="flex" alignItems="center">
						{name}
					</Typography>
					<Box marginRight={'10px'}>
						<IntranetListItemButtons onClickDelete={() => setConfirmDialogToggle((prevState) => !prevState)} onClickEdit={() => setOpenAddFolderDialog(true)}  listeners={listeners} />
						{confirmDialogToggle && <ConfirmationDialog open={confirmDialogToggle} onCancel={() => setConfirmDialogToggle(false)} onConfirm={handleDeleteLinkFolder} text={'folder'} />}
					</Box>
				</Box>
				<IntranetLinks links={folderLinks} />
			</Paper>
			{openAddFolderDialog === true && <AddFolderDialog open={openAddFolderDialog} setOpenAddFolderDialog={setOpenAddFolderDialog} mode={'edit'} selectedFolderName={name} selectedfolderType={'1'} selectedFolderId={id} />}
		</>
	);
}
