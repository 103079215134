import React from 'react';
import { Box } from '@mui/material';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import FileItem from './FileItem'; // Import the FileItem component
import DataNotAvailable from './DataNotAvailable';
import { IntranetContext } from './IntranetContext';
import { DragOverlay } from '@dnd-kit/core';

export const IntranetFiles = ({ files }) => {
	const { activeFile, fileFolders } = React.useContext(IntranetContext);
	if (!files || files.length === 0) {
		return <DataNotAvailable>Keine Datei hinzugefügt</DataNotAvailable>;
	}

	return (
		<Box>
			<SortableContext items={files.map((file) => file.file_id)} strategy={verticalListSortingStrategy}>
				{files.map((file) => (
					<FileItem key={file.file_id} id={file.file_id} file={file} />
				))}
			</SortableContext>
			{activeFile && (
				<>
					{activeFile.type === 'file' && (
						<DragOverlay>
							<FileItem
								id={activeFile.id}
								file={fileFolders.flatMap(folder => folder.files).find(file => file.file_id === activeFile.id)}
							/>
						</DragOverlay>
					)}
				</>
			)}
		</Box>
	);
};
