import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  required,
  ListButton,
  TopToolbar,
  Pagination,
} from "react-admin";

const ProjectEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="< Zurück" />
  </TopToolbar>
);

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />
);

export const CtypeList = () => (
  <List perPage={1000} pagination={<ProjectPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const CtypeEdit = (props) => (
  <Edit title="Edit Type of cost" actions={<ProjectEditActions />}>
    <SimpleForm>
      <TextInput readOnly source="id" variant="outlined" />
      <TextInput label="Full Name" source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);

export const CtypeCreate = (props) => (
  <Create
    title="Add New Type of cost"
    {...props}
    actions={<ProjectEditActions />}
  >
    <SimpleForm>
      <TextInput label="Type of cost" source="name" validate={required()} />
    </SimpleForm>
  </Create>
);
