import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import IntranetNotesRegions from './IntranetNotesRegions';
import {
	closestCorners,
	DndContext,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors
} from '@dnd-kit/core';

import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { IntranetContext } from './IntranetContext';
import config from '../../config';

const apiUrl = config.apiUrl;

export default function IntranetNotesPage({ isEditing }) {
	const { notesData: regionList, setNotesData: setRegionList, setActiveNote } = React.useContext(IntranetContext);
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(TouchSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates
		})
	);
	if (!regionList || regionList.length === 0) {
		return <Typography>No data available</Typography>;
	}

	const handleDragStart = (event) => {
		const { active } = event;
		const activeId = active.id;		
		let isRegion = null;
		isRegion = regionList.some(region => region.region_id === activeId);
		setActiveNote({ id: activeId, type: isRegion ? 'region' : 'note' });
	};

	const handleDragEnd = (event) => {
		const { active, over } = event;
		if (!active || !over) return;
		if (active.id === over.id) return;

		const activeId = active.id;
		const overId = over.id;

		if (!activeId || !overId) {
			console.error('Invalid drag end event:', { active, over });
			return;
		}

		const currentRegions = [...regionList];  // Assuming `existingRegions` is your current state
		const updatedRegions = handleDragAndDrop(currentRegions, activeId, overId);

		setRegionList(updatedRegions);

		setActiveNote(null);
		handleRegionNotesOrder(updatedRegions);
	};

	const handleDragAndDrop = (regions, activeId, overId) => {
		const isRegionDrag = regions.some(region => region.region_id === activeId);

		if (isRegionDrag) {
			// Handle folder sorting
			const activeRegionIndex = regions.findIndex(region => region.region_id === activeId);
			const overRegionIndex = regions.findIndex(region => region.region_id === overId);
			if (activeRegionIndex === -1 || overRegionIndex === -1) {
				console.error('Could not find active or over region');
				return regions;
			}
			const newRegionsOrder = arrayMove(regions, activeRegionIndex, overRegionIndex);
			newRegionsOrder.forEach((folder, index) => {
				regions.folder_display_order = index;
			});

			return newRegionsOrder;
		} else {
			const sourceRegionIndex = regions.findIndex(region =>
				region.notes.some(note => note.note_id === activeId)
			);
			let targetRegionIndex = regions.findIndex(region =>
				region.notes.some(note => note.note_id === overId)
			);

			if (targetRegionIndex === -1) {
				targetRegionIndex = regions.findIndex(region => region.region_id === overId);
			}
			if (sourceRegionIndex === -1 || targetRegionIndex === -1) {
				console.error('Could not find source or target folder');
				return regions;
			}
			const updatedRegions = [...regions];

			// If dragging and dropping within the same folder, sort the files
			if (sourceRegionIndex === targetRegionIndex) {
				const region = updatedRegions[sourceRegionIndex];
				const activeNoteIndex = region.notes.findIndex(note => note.note_id === activeId);
				const overNoteIndex = region.notes.findIndex(note => note.note_id === overId);
				if (activeNoteIndex !== -1 && overNoteIndex !== -1) {
					region.notes = arrayMove(region.notes, activeNoteIndex, overNoteIndex);
					region.notes.forEach((note, index) => {
						note.note_display_order = index;
					});
				}
			} else {
				// else dragging and dropping the notes from one to another folder
				const sourceRegion = updatedRegions[sourceRegionIndex];
				const draggedNote = sourceRegion.notes.find(note => note.note_id === activeId);
				updatedRegions[sourceRegionIndex].notes = sourceRegion.notes.filter(
					note => note.note_id !== activeId
				);
				updatedRegions[targetRegionIndex].notes.push(draggedNote);
				updatedRegions[targetRegionIndex].notes.forEach((note, index) => {
					note.note_display_order = index;
				});
			}
			return updatedRegions;
		}
	};

	const handleRegionNotesOrder = async (updatedRegions) => {
		updatedRegions.forEach((regions, regionIndex) => {
			regions.region_display_order = regionIndex;
			regions.notes.forEach((note, noteIndex) => {
				note.note_display_order = noteIndex;
				note.selected_region_id = regions.region_id;
			});
		});
		try {
			const response = await fetch(apiUrl + `?section=intranet&action=saveOriginNotesData`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(regionList),
			});
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const result = await response.json();
		} catch (err) {
			console.log('order not saved');
		}
	}

	return (
		<Box
			sx={{ width: '100%' }}
		>
			<DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
				<SortableContext
					items={regionList.map((region) => region.region_id)}
					strategy={verticalListSortingStrategy}
				>
					{regionList.map((region) => (
						<IntranetNotesRegions
							key={region.region_id}
							id={region.region_id}
							name={region.region_name}
							notes={region.notes}
						/>
					))}
				</SortableContext>
			</DndContext>
		</Box>
	);
}
