import React, { useState } from "react";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContext } from "react";
import { IntranetContext } from "./IntranetContext";
import ConfirmationDialog from "./ConfirmationDialog";
import Tooltip from "@mui/material/Tooltip";
import CreateLinkPopup from "./CreateLinkPopup";
import config from "../../config";
import { Link } from "@mui/material";

const apiUrl = config.apiUrl;
const ALLOWED_FILE_EXTENSIONS = ["doc", "docx", "ppt", "pptx", "xls", "xlsx", "csv"];

export const Item = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    border: "3px solid #d3d3d37a",
    boxShadow: "none",
    borderRadius: 0,
    // padding: '5px 8px',
    gap: "10px",
    margin: "7px auto",
    transition: "border 0.2s ease-in-out",
    "&:hover": {
        border: "3px solid grey",
        backgroundColor: "#f2f2f2",
    },
});

const actionButtons = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "8vw",
    margin: 0,
    padding: 0,
    opacity: 0,
    transition: "opacity 0.2s ease-in-out",
    "&:hover": {
        opacity: 1,
    },
};

const actionIcons = {
    borderRadius: "0px",
    margin: 0,
    padding: 0,
};

export const IntranetListItemButtons = ({ onClickDelete, onClickEdit, listeners, notShowDeleteIcon = false, onClickPreview, identifier = "", fileExtension = "" }) => {
    return (
        <Stack direction="row" spacing={0} columnGap={0.5} sx={actionButtons}>
            {/* {
        identifier === 'file' && ALLOWED_FILE_EXTENSIONS.includes(fileExtension) &&
        <IconButton size="medium" color="primary" sx={actionIcons} onClick={onClickPreview}>
            <FileDownloadIcon fontSize="small" />
        </IconButton>
      } */}
            {/* {
        identifier === 'file' && !ALLOWED_FILE_EXTENSIONS.includes(fileExtension) &&
        <IconButton size="medium" color="primary" sx={actionIcons} onClick={onClickPreview}>
          <VisibilityIcon fontSize="small" />
        </IconButton>
      } */}
            {!notShowDeleteIcon && (
                <IconButton size="medium" color="primary" sx={actionIcons} onClick={onClickEdit}>
                    <EditIcon fontSize="small" />
                </IconButton>
            )}
            {!notShowDeleteIcon && (
                <IconButton size="medium" color="error" sx={actionIcons} onClick={onClickDelete}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            )}
            <IconButton size="large" color="dark" sx={actionIcons} {...listeners}>
                <MenuIcon fontSize="inherit" />
            </IconButton>
        </Stack>
    );
};

const IntranetLink = ({ id, link, isEditing }) => {
    const { handleDelete } = useContext(IntranetContext);
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const [confirmDialogToggle, setConfirmDialogToggle] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    const handleDeleteLink = async () => {
        try {
            const response = await fetch(apiUrl + "?section=intranet&action=deleteLink&id=" + id, {
                method: "POST",
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const result = await response.json();
            handleDelete(id, "link");
            console.log("link deleted succesfully", result);
        } catch (err) {
            console.log("link not deleted");
        }
    };
    return (
        <>
            <Box ref={setNodeRef} style={style} {...attributes} key={id}>
                <Item>
                    <Link href={link.link_address} underline="none" color="inherit" target="_blank" sx={{ flex: "1", padding: "5px 8px" }}>
                        <Box display="flex" alignItems="center" gap="12px" maxWidth="300px">
                            <Box sx={{ color: "#808080b8" }} display="flex" alignItems="center">
                                <CallMadeIcon />
                            </Box>
                            <Typography variant="p" noWrap>
                                <Tooltip title={link.link_name} placement="bottom" arrow>
                                    <span>{link.link_name}</span>
                                </Tooltip>
                            </Typography>
                        </Box>
                    </Link>
                    <Box sx={{ padding: "5px 8px" }}>
                        <IntranetListItemButtons onClickDelete={() => setConfirmDialogToggle((prevState) => !prevState)} onClickEdit={() => setOpenPopup(true)} listeners={listeners} />
                        <ConfirmationDialog open={confirmDialogToggle} onCancel={() => setConfirmDialogToggle(false)} onConfirm={handleDeleteLink} text={"link"} />
                    </Box>
                </Item>
            </Box>
            {openPopup === true && (
                <CreateLinkPopup openPopup={openPopup} setOpenPopup={setOpenPopup} mode="edit" identifierValue="link" currentLinkId={id} selectedFolderValue={link.selected_folder_id} linkNameValue={link.link_name} linkAddressValue={link.link_address} selectedRegionsValue={link.link_region_id} />
            )}
        </>
    );
};

export default IntranetLink;
