import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

export default function FolderSelect({ folders, onChange, selectedValue='' }) {
    const [folder, setFolder] = React.useState(selectedValue);

    const handleChange = (event) => {
        setFolder(event.target.value);
        if (onChange) {
            onChange(event); // Pass the full event object
        }
    };

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { width: '25ch' }
            }}
            noValidate
            autoComplete="off"
        >
            <TextField
                id="outlined-select-folder" 
                select
                label="Ordner auswählen"
                value={folder}
                onChange={handleChange} // Attach the handleChange here
            >
                {folders.map((folder) => (
                    <MenuItem key={folder.folder_id || 1} value={folder.folder_id || ''}>
                        {folder.folder_name || 'No results'}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
}
