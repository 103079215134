import React from 'react';
import { Box, IconButton, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const EditButton = styled(IconButton)({
	border: '1px solid #2196f3',
	backgroundColor: '#2196f31a'
});

const SaveButton = styled(IconButton)({
	border: '1px solid #2196f3',
	backgroundColor: '#2196f31a',
});

export const CreateButton = ({ children,...props }) => (
	<Button variant="text" size="small" startIcon={<AddIcon />} sx={{ padding: '5px 12px' }} {...props}>
		{children ? children : 'Create'}
	</Button>
);

export const EditListButton = ({ active, onEdit, onSave, ...props }) => {
	return (
		<Box display="flex" alignItems="center">
			{active ? (
				<SaveButton component={NavLink} size="small" color='primary' onClick={onSave} sx={{ borderRadius: '3px' }} {...props}>
					<DoneIcon fontSize="small" />
				</SaveButton>
			) : (
				<IconButton component={NavLink} size="small" color="primary" sx={{ borderRadius: '3px' }} {...props}>
					<EditIcon fontSize="small" />
				</IconButton>
			)}
		</Box>
	);
};
