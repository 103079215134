import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import { IntranetContext } from './IntranetContext';

export default function RegionSelect({ onChange, selectedRegionValue = [], type = '' }) {
    const { regionsData: regions } = React.useContext(IntranetContext);
    const [regionsSelected, setRegionsSelected] = useState(selectedRegionValue);

    const handleChange = (event) => {
        const selectedRegions = event.target.value;
        setRegionsSelected(selectedRegions);
        onChange(selectedRegions);
    };

    const handleDelete = (event, value) => {
        event.preventDefault();
        const newRegions = regionsSelected.filter(item => item.id !== value.id);
        setRegionsSelected(newRegions);
        onChange(newRegions);
    };

    return (
        <Box
            component="form"

            noValidate
            autoComplete="off"
        >
            {type !== "note" ? <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-chip-label">Speichern unter Region</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={regionsSelected}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip
                                    key={value.id}
                                    label={value.name}
                                    clickable
                                    deleteIcon={
                                        <CancelIcon
                                            onMouseDown={(event) => event.stopPropagation()}
                                        />
                                    }
                                    onDelete={(e) => handleDelete(e, value)}
                                />
                            ))}
                        </Box>
                    )}
                >
                    {regions.map((option) => (
                        <MenuItem
                            key={option.id}
                            value={option}
                            style={{
                                backgroundColor: regionsSelected?.find((item) => item.id === option.id) ? '#edf4fb' : 'white', // Change background color when selected
                            }}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl> :
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-regions-label">Regions</InputLabel>
                    <Select
                        labelId="demo-regions-label"
                        id="demo-multiple-chip"
                        value={regionsSelected}
                        onChange={handleChange}
                    >
                        {regions.map((option) => (
                            <MenuItem
                                key={option.id}
                                value={option.id}
                            >
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
        </Box>
    );
}
