import React, { useState, useContext } from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IntranetListItemButtons } from './IntranetLink';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IntranetContext } from './IntranetContext';
import Tooltip from '@mui/material/Tooltip';
import ConfirmationDialog from './ConfirmationDialog';
import config from '../../config';
import CreateLinkPopup from './CreateLinkPopup';

const apiUrl = config.apiUrl;

const Note = styled(Paper)({
	padding: '10px 12px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginBottom: '10px',
	borderRadius: '3px'
});

const NoteContent = styled(Box)({
	padding: '5px 12px',
	width: '100%',
});

export default function IntranetNoteItem({ id, text, headline, color, note }) {
	const { handleDelete } = useContext(IntranetContext);
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
	const [confirmDialogToggle, setConfirmDialogToggle] = useState(false);
	const [openPopup, setOpenPopup] = useState(false);

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		backgroundColor: color || 'lightblue',
		boxShadow: color === ('#ffgghh' || 'white' || '#ffffff') && '0 0 4px 1px lightgray'
	};
	const handleDeleteNote = async () => {
		try {
			const response = await fetch(apiUrl + '?section=intranet&action=deleteNote&id=' + id, {
				method: 'POST',
			});
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const result = await response.json();
			handleDelete(id, 'note');
			setConfirmDialogToggle(false);
			console.log('note deleted succesfully', result);
		} catch (err) {
			console.log('note not deleted');
		}
	}
	return (
		<>
			<Box maxWidth={'700px'}>
				<Note ref={setNodeRef} style={style} {...attributes} elevation={0} >
					<NoteContent>
						<Box display='flex' justifyContent='space-between' sx={{width:' 100%'}}>
							<Typography
								variant="p"
								component="p"
								marginBottom="3px"
								sx={{ fontWeight: 600, fontSize: '0.9rem'}}
								>
								{headline}
							</Typography>
							<Box sx={{ display: 'flex', width:'25%', justifyContent: 'end' }}>
								<IntranetListItemButtons onClickDelete={() => setConfirmDialogToggle((prevState) => !prevState)} onClickEdit={() => setOpenPopup(true)} listeners={listeners} />
							</Box>
						</Box>
						<div dangerouslySetInnerHTML={{__html: text}}></div>
					</NoteContent>
					<ConfirmationDialog open={confirmDialogToggle} onCancel={() => setConfirmDialogToggle(false)} onConfirm={handleDeleteNote} text={'note'} />
				</Note>
			</Box>
			{
				openPopup === true && <CreateLinkPopup
					openPopup={openPopup}
					setOpenPopup={setOpenPopup}
					mode='edit'
					identifierValue='note'
					currentNoteId={id}
					currentHeadline={headline}
					currentText={text}
					currentColor={color}
					selectedRegionsValue={note.selected_region_id}
				/>
			}  </>
	);
}