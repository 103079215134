import React, { useState } from "react";
import { Box, Grid, Paper, Typography, CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { styled } from "@mui/material/styles";
import { Link, useGetList } from "react-admin";
import DashboardCard from "./DashboardCard";

const StyledPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  padding: "10px 15px",
  borderRadius: "10px",
  margin: "5px",
});
export const NeukundenRegion = ({ regions }) => {
  const [selectedRegion, setSelectedRegion] = useState("all");
  const { data, isLoading } = useGetList("dashboardCards&action=neukunden", {
    filter:
      selectedRegion && selectedRegion !== "all"
        ? { regionId: selectedRegion }
        : {},
  });
  return (
    <StyledPaper>
      <Stack>
        <Stack
          direction="row"
          mb={1}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h4" sx={{ px: 1, fontSize: "1.5rem" }}>
            Neukunden
          </Typography>
          <TextField
            select
            sx={{ minWidth: 150 }}
            label="Regions"
            value={selectedRegion}
            onChange={(event) => setSelectedRegion(event.target.value)}
          >
            <MenuItem key={0} value="all">
              All Regions
            </MenuItem>
            {regions.map((region) => (
              <MenuItem key={region.id} value={region.id}>
                {region.name}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        {!isLoading ? (
          <Grid key={4} container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DashboardCard
                name="Dieses Jahr"
                trackingValue={parseInt(data[0].thisYear)}
                defaultGrid={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DashboardCard
                name="Dieser Monat"
                trackingValue={parseInt(data[0].thisMonth)}
                defaultGrid={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DashboardCard
                name="Letzter Monat"
                trackingValue={parseInt(data[0].lastMonth)}
                defaultGrid={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DashboardCard
                name="Durchschnitt letzte 6 Monate"
                trackingValue={parseInt(data[0].avg)}
                defaultGrid={false}
              />
            </Grid>
          </Grid>
        ) : (
          <Box
            height={300}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Box>
        )}
      </Stack>
    </StyledPaper>
  );
};
export const UmsatzRegion = ({ regions, title }) => {
  const [selectedRegion, setSelectedRegion] = useState("all");
  const { data, isLoading } = useGetList(`dashboardCards&action=` + title, {
    filter:
      selectedRegion && selectedRegion !== "all"
        ? { regionId: selectedRegion }
        : {},
  });
  return (
    <StyledPaper>
      <Stack>
        <Stack
          direction="row"
          mb={1}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h4" sx={{ px: 1, fontSize: "1.5rem" }}>
            {title === "umsatz" && "Umsatz"}
            {title === "umsatzFertiggestellt" && "Umsatz Fertiggestellt"}
          </Typography>
          <TextField
            select
            sx={{ minWidth: 150 }}
            label="Regions"
            value={selectedRegion}
            onChange={(event) => setSelectedRegion(event.target.value)}
          >
            <MenuItem key={0} value="all">
              All Regions
            </MenuItem>
            {regions.map((region) => (
              <MenuItem key={region.id} value={region.id}>
                {region.name}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        {!isLoading ? (
          <Grid key={4} container spacing={"auto"}>
            <Grid item xs={12} sm={6}>
              <DashboardCard
                name="Heute"
                trackingValue={parseInt(data[0].todayUmsatz)}
                tracking2Value={parseInt(data[0].todayDeckungsbeitrag)}
                percent={data[0].todayPercent}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DashboardCard
                name="Dieser Monat"
                trackingValue={parseInt(data[0].thisMonthUmsatz)}
                tracking2Value={parseInt(data[0].thisMonthDeckungsbeitrag)}
                percent={data[0].thisMonthPercent}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DashboardCard
                name="Letzter Monat"
                trackingValue={parseInt(data[0].lastMonthUmsatz)}
                tracking2Value={parseInt(data[0].lastMonthDeckungsbeitrag)}
                percent={data[0].lastMonthPercent}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DashboardCard
                name="Letzte 6 Monate"
                trackingValue={parseInt(data[0].lastSixMonthsUmsatz)}
                tracking2Value={parseInt(data[0].lastSixMonthsDeckungsbeitrag)}
                percent={data[0].lastSixMonthsPercent}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DashboardCard
                name="Durchschnitt 6 Monate"
                trackingValue={parseFloat(data[0].avgUmsatz)}
                tracking2Value={parseFloat(data[0].avgDeckungsbeitrag)}
                percent={data[0].avgPercent}
              />
            </Grid>
          </Grid>
        ) : (
          <Box
            height={300}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Box>
        )}
      </Stack>
    </StyledPaper>
  );
};
export const TopKunden = ({ regions, isUserDashboard, firstRegionId }) => {
  const [selectedRegion, setSelectedRegion] = useState(
    isUserDashboard ? firstRegionId : "all"
  );
  const [selectedTime, setSelectedTime] = useState("all");
  const filters = {
    ...(selectedRegion && { regionId: selectedRegion }),
    ...(selectedTime && selectedTime !== "all" && { timeRange: selectedTime }),
  };

  const { data, isLoading } = useGetList("dashboardCards&action=topKunden", {
    filter: filters,
  });

  return (
    <StyledPaper>
      <Stack>
        <Box
          display={"flex"}
          flexWrap={"wrap-reverse"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h4" sx={{ p: 1, fontSize: "1.5rem" }}>
            {selectedTime === "all" ? "All-Time Top-Kunden" : "Top-Kunken"}
          </Typography>
          <Box display={"flex"} flexWrap={"wrap"} gap={1}>
            <TextField
              select
              sx={{ minWidth: 120 }}
              label="Time"
              value={selectedTime}
              onChange={(event) => setSelectedTime(event.target.value)}
              size="small"
            >
              <MenuItem value="all">All Time</MenuItem>
              <MenuItem value="thisMonth">Dieser Monat</MenuItem>
              <MenuItem value="lastMonth">Letzter Monat</MenuItem>
              <MenuItem value="lastQuater">Letzte 3 Monate</MenuItem>
              <MenuItem value="lastSixMonths">Letzte 6 Monate</MenuItem>
              <MenuItem value="lastYear">Letzte 12 Monate</MenuItem>
            </TextField>
            <TextField
              select
              sx={{ minWidth: 120 }}
              label="Regions"
              value={selectedRegion}
              onChange={(event) => setSelectedRegion(event.target.value)}
              size="small"
            >
              <MenuItem key={0} value="all">
                All Regions
              </MenuItem>
              {regions.map((region) => (
                <MenuItem key={region.id} value={region.id}>
                  {region.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        {!isLoading ? (
          <Box>
            <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
              <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell width={"85px"}>Ranking</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="right" width={"100px"}>
                      Jobs
                    </TableCell>
                    <TableCell align="right">Revenue</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length === 0 && (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        textAlign: "center",
                      }}
                    >
                      <TableCell align="center" colSpan={5}>
                        No Record Found
                      </TableCell>
                    </TableRow>
                  )}
                  {data.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">
                        {row.ranking === "1" && (
                          <EmojiEventsIcon style={{ color: "gold" }} />
                        )}

                        {row.ranking === "2" && (
                          <EmojiEventsIcon style={{ color: "#C0C0C0" }} />
                        )}
                        {row.ranking === "3" && (
                          <EmojiEventsIcon style={{ color: "#b08d57" }} />
                        )}
                        {row.ranking !== "1" &&
                          row.ranking !== "2" &&
                          row.ranking !== "3" &&
                          row.ranking}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Link
                          to={`/kundenlistes/${row.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          {row.name}
                        </Link>
                      </TableCell>
                      <TableCell align="right">{row.jobs}</TableCell>
                      <TableCell align="right">{row.revenue}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Box
            height={300}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Box>
        )}
      </Stack>
    </StyledPaper>
  );
};
export const NochNichtAbgerechnet = ({ regions }) => {
  const [selectedRegion, setSelectedRegion] = useState("all");
  const { data, isLoading } = useGetList(
    "dashboardCards&action=nochNichtAbgerechnet",
    {
      filter:
        selectedRegion && selectedRegion !== "all"
          ? { regionId: selectedRegion }
          : {},
    }
  );
  return (
    <StyledPaper>
      <Stack>
        <Box
          mb={2}
          gap={2}
          display={"flex"}
          flexWrap={"wrap-reverse"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h4" sx={{ px: 1, fontSize: "1.5rem" }}>
            Noch nicht abgerechnet
          </Typography>
          <TextField
            select
            sx={{ minWidth: 150 }}
            label="Regions"
            value={selectedRegion}
            onChange={(event) => setSelectedRegion(event.target.value)}
          >
            <MenuItem key={0} value="all">
              All Regions
            </MenuItem>
            {regions.map((region) => (
              <MenuItem key={region.id} value={region.id}>
                {region.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        {!isLoading ? (
          <Grid key={4} container spacing={2}>
            <Grid item xs={12} sm={6} lg={12}>
              <DashboardCard
                name="Anzahl"
                trackingValue={parseInt(data[0].totalProjects)}
                defaultGrid={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={12}>
              <DashboardCard
                name="Umsatz"
                trackingValue={parseInt(data[0].revenue)}
                defaultGrid={false}
              />
              {/* <DashboardCard name="Umsatz" trackingValue={parseInt(data[0].revenue)} tracking2Value={parseInt(data[0].deckungsbeitrag)} percent={parseInt(data[0].percent)}  /> */}
            </Grid>
          </Grid>
        ) : (
          <Box
            height={300}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Box>
        )}
      </Stack>
    </StyledPaper>
  );
};
export const Worklist = ({ regions }) => {
  const { data, isLoading } = useGetList("dashboardCards&action=worklist");
  return (
    <StyledPaper>
      <Stack>
        <Box
          display={"flex"}
          flexWrap={"wrap-reverse"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h4" sx={{ p: 1, fontSize: "1.5rem" }}>
            Worklist
          </Typography>
        </Box>
        {!isLoading ? (
          <Box sx={{ minHeight: 300 }}>
            <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
              <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right" width={"100px"}>
                      Red
                    </TableCell>
                    <TableCell align="right" width={"100px"}>
                      Yellow
                    </TableCell>
                    <TableCell align="right" width={"100px"}>
                      Pink
                    </TableCell>
                    <TableCell align="right" width={"100px"}>
                      Green
                    </TableCell>
                    <TableCell align="right">Unfinished Tasks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length === 0 && (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        textAlign: "center",
                      }}
                    >
                      <TableCell align="center" colSpan={5}>
                        No Record Found
                      </TableCell>
                    </TableRow>
                  )}
                  {data.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Link
                          to={`/tasks?filter={"workId":"${row.id}"}`}
                          style={{ textDecoration: "none" }}
                        >
                          {row.name}
                        </Link>
                      </TableCell>
                      <TableCell align="right">{row.red}</TableCell>
                      <TableCell align="right">{row.yellow}</TableCell>
                      <TableCell align="right">{row.pink}</TableCell>
                      <TableCell align="right">{row.green}</TableCell>
                      <TableCell align="right">{row.notFinished}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Box
            height={300}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Box>
        )}
      </Stack>
    </StyledPaper>
  );
};
