import * as React from 'react';
import { TableCell, TableRow, Table, TableBody, Paper, Box, Grid, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CreateButton } from './IntranetGrid/HeaderButtons';
import {
	DndContext,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors,
	closestCorners
} from '@dnd-kit/core';
import { useState, useEffect } from 'react';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import IntranetColumn from './IntranetGrid/IntranetColumn';
import CreateLinkPopup from './IntranetGrid/CreateLinkPopup';
import IntranetNotesPage from './IntranetGrid/IntranetNotesPage';
import AddFolderDialog from './IntranetGrid/AddFolderDialog';
import { IntranetContext, IntranetProvider } from './IntranetGrid/IntranetContext';
import { useContext } from 'react';
import config from '../config';

const apiUrl = config.apiUrl;
const HeaderBox = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between'
});

export const IntranetList = () => {
	const [data, setdata] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(apiUrl + `?section=intranet&action=index`);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const result = await response.json();
				setdata(result.data);
				setIsLoading(false);
			} catch (err) {
				setIsLoading(false);
			}
		};
		fetchData();
	}, []);

	return (
		<IntranetProvider>
			<IntranetData data={data} isLoading={isLoading} />
		</IntranetProvider>
	);
};

const IntranetData = ({ data, isLoading }) => {
	const {
		linksFolders,
		fileFolders,
		notesData,
		setLinksFolders,
		setFileFolders,
		setNotesData,
		setRegionsData,
		setActiveLink,
		setActiveFile
	} = useContext(IntranetContext);
	const [openPopup, setOpenPopup] = useState(false);
	const [openAddFolderDialog, setOpenAddFolderDialog] = useState(false);
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(TouchSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates
		})
	);

	useEffect(() => {
		if (!isLoading && data && data.length > 0) {
			const linkFoldersArray = Object.values(data[0].linkFoldersData).sort(
				(a, b) => a.folder_display_order - b.folder_display_order
			);
			const fileFoldersArray = Object.values(data[0].fileFoldersData).sort(
				(a, b) => a.folder_display_order - b.folder_display_order
			);
			const notesDataArray = Object.values(data[0].notesData).sort(
				(a, b) => a.region_display_order - b.region_display_order
			);
			linkFoldersArray.forEach((folder) => {
				folder.links = folder.links.sort((a, b) => a.link_display_order - b.link_display_order);
			});
			fileFoldersArray.forEach((folder) => {
				folder.files = folder.files.sort((a, b) => a.file_display_order - b.file_display_order);
			});
			notesDataArray.forEach((region) => {
				region.notes = region.notes.sort((a, b) => a.note_display_order - b.note_display_order);
			});
			setLinksFolders(linkFoldersArray);
			setFileFolders(fileFoldersArray);
			setNotesData(notesDataArray);
			setRegionsData(data[0].regions);
		}
	}, [isLoading, data]);

	const handleDragStart = (event, type) => {
		const { active } = event;
		const activeId = active.id;
		let isFolder = null;
		switch (type) {
			case 'link':
				isFolder = linksFolders.some(folder => folder.folder_id === activeId);
				setActiveLink({ id: activeId, type: isFolder ? 'folder' : 'link' });
				break;
			case 'file':
				isFolder = fileFolders.some(folder => folder.folder_id === activeId);
				setActiveFile({ id: activeId, type: isFolder ? 'folder' : 'file' });
				break;
			default:
				console.error('Invalid type:', type);
				break;
		}
	};

	const handleLinkFolderDragEnd = (event) => {
		const { active, over } = event;
		if (!active || !over) return;
		if (active.id === over.id) return;

		const activeId = active.id;
		const overId = over.id;

		if (!activeId || !overId) {
			console.error('Invalid drag end event:', { active, over });
			return;
		}

		const folders = [...linksFolders];  // Assuming `existingFolders` is your current state
		const updatedFolders = handleDragAndDrop('links', folders, activeId, overId);

		setLinksFolders(updatedFolders);

		setActiveLink(null);

		handeLinksOrder(updatedFolders);
	};

	const handleFileFolderDragEnd = (event) => {
		const { active, over } = event;
		if (!active || !over) return;
		if (active.id === over.id) return;

		const activeId = active.id;
		const overId = over.id;

		if (!activeId || !overId) {
			console.error('Invalid drag end event:', { active, over });
			return;
		}
		
		const folders = [...fileFolders];  // Assuming `existingFolders` is your current state
		const updatedFolders = handleDragAndDrop('files', folders, activeId, overId);

		setFileFolders(updatedFolders);
		
		setActiveFile(null);

		handleFilesOrder(updatedFolders);
	};

	const handleDragAndDrop = (types, folders, activeId, overId) => {
		const isFolderDrag = folders.some(folder => folder.folder_id === activeId);

		if (isFolderDrag) {
			// Handle folder sorting
			const activeFolderIndex = folders.findIndex(folder => folder.folder_id === activeId);
			const overFolderIndex = folders.findIndex(folder => folder.folder_id === overId);
			if (activeFolderIndex === -1 || overFolderIndex === -1) {
				console.error('Could not find active or over folder');
				return folders;
			}
			const newFoldersOrder = arrayMove(folders, activeFolderIndex, overFolderIndex);
			newFoldersOrder.forEach((folder, index) => {
				folder.folder_display_order = index;
			});

			return newFoldersOrder;
		} else if (types === 'files') {
			// Handle file sorting and moving between folders
			const sourceFolderIndex = folders.findIndex(folder =>
				folder.files.some(file => file.file_id === activeId)
			);
			let targetFolderIndex = folders.findIndex(folder =>
				folder.files.some(file => file.file_id === overId)
			);

			// If the target folder is not found by link, check if it's being dropped into an empty folder
			if (targetFolderIndex === -1) {
				targetFolderIndex = folders.findIndex(folder => folder.folder_id === overId);
			}

			if (sourceFolderIndex === -1 || targetFolderIndex === -1) {
				console.error('Could not find source or target folder');
				return folders;
			}

			const updatedFolders = [...folders];

			// If dragging and dropping within the same folder, sort the files
			if (sourceFolderIndex === targetFolderIndex) {
				const folder = updatedFolders[sourceFolderIndex];
				const activeFileIndex = folder.files.findIndex(file => file.file_id === activeId);
				const overFileIndex = folder.files.findIndex(file => file.file_id === overId);

				if (activeFileIndex !== -1 && overFileIndex !== -1) {
					folder.files = arrayMove(folder.files, activeFileIndex, overFileIndex);
					folder.files.forEach((file, index) => {
						file.file_display_order = index;
						file.selected_folder_id = folder;
					});
				}
			} else {
				// else dragging and dropping the files from one to another folder
				const sourceFolder = updatedFolders[sourceFolderIndex];
				const draggedFile = sourceFolder.files.find(file => file.file_id === activeId);
				updatedFolders[sourceFolderIndex].files = sourceFolder.files.filter(
					file => file.file_id !== activeId
				);
				updatedFolders[targetFolderIndex].files.push(draggedFile);
				updatedFolders[targetFolderIndex].files.forEach((file, index) => {
					file.file_display_order = index;
				});
			}

			return updatedFolders;
		} else if (types === 'links') {
			// Handle link sorting and moving between folders
			const sourceFolderIndex = folders.findIndex(folder =>
				folder.links.some(link => link.link_id === activeId)
			);
			let targetFolderIndex = folders.findIndex(folder =>
				folder.links.some(link => link.link_id === overId)
			);

			// If the target folder is not found by link, check if it's being dropped into an empty folder
			if (targetFolderIndex === -1) {
				targetFolderIndex = folders.findIndex(folder => folder.folder_id === overId);
			}
			if (sourceFolderIndex === -1 || targetFolderIndex === -1) {
				console.error('Could not find source or target folder');
				return folders;
			}
			const updatedFolders = [...folders];

			// If dragging and dropping within the same folder, sort the links
			if (sourceFolderIndex === targetFolderIndex) {
				const folder = updatedFolders[sourceFolderIndex];
				const activeLinkIndex = folder.links.findIndex(link => link.link_id === activeId);
				const overLinkIndex = folder.links.findIndex(link => link.link_id === overId);
				if (activeLinkIndex !== -1 && overLinkIndex !== -1) {
					folder.links = arrayMove(folder.links, activeLinkIndex, overLinkIndex);
					folder.links.forEach((link, index) => {
						link.link_display_order = index;
						link.selected_folder_id = folder;
					});
				}
			} else {
				// else dragging and dropping the links from one to another folder
				const sourceFolder = updatedFolders[sourceFolderIndex];
				const draggedLink = sourceFolder.links.find(link => link.link_id === activeId);
				updatedFolders[sourceFolderIndex].links = sourceFolder.links.filter(
					link => link.link_id !== activeId
				);
				updatedFolders[targetFolderIndex].links.push(draggedLink);
				updatedFolders[targetFolderIndex].links.forEach((link, index) => {
					link.link_display_order = index;
				});
			}

			return updatedFolders;
		}
	};

	const handeLinksOrder = async (updatedFolders) => {
		updatedFolders.forEach((folder, folderIndex) => {
			folder.folder_display_order = folderIndex;
			folder.links.forEach((link, linkIndex) => {
				link.link_display_order = linkIndex;
				link.selected_folder_id = folder.folder_id;
			});
		});

		try {
			const response = await fetch(apiUrl + `?section=intranet&action=saveLinkFoldersData`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(updatedFolders),
			});
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const result = await response.json();
			console.log('order saved succesfully', result);
		} catch (err) {
			console.log('order not saved');
		}
	}

	const handleFilesOrder = async (updatedFolders) => {
		updatedFolders.forEach((folder, folderIndex) => {
			folder.folder_display_order = folderIndex;
			folder.files.forEach((file, fileIndex) => {
				file.file_display_order = fileIndex;
				file.selected_folder_id = folder.folder_id;

			});
		});

		try {
			const response = await fetch(apiUrl + `?section=intranet&action=saveFileFoldersData`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(updatedFolders),
			});
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const result = await response.json();
			console.log('order saved succesfully', result);
		} catch (err) {
			console.log('order not saved');
		}
	}

	function handlePopupOpen(isOpen) {
		setOpenPopup(isOpen);
	}


	if (isLoading) {
		return (
			<Paper>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>Loading...</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
		);
	} else {
		return (
			<Paper elevation={0} sx={{ padding: '20px' }}>
				<Box display="flex" justifyContent="right" columnGap={0.5}>
					<CreateButton 
						onClick={() => handlePopupOpen(true)} 
					>
						Neuer Eintrag
					</CreateButton>
					<CreateButton
						sx={{ left: '10px', marginBottom: '5px' }}
						variant="contained"
						onClick={() => setOpenAddFolderDialog(true)}
					>
						Neuer Ordner
					</CreateButton>
				</Box>
				<Grid container spacing={6}>
					<Grid item xs={12} sm={12} md={6} lg={4}>
						<HeaderBox>
							<Typography variant="h6" component="h6">
								Schnellzugriff
							</Typography>
						</HeaderBox>
						<Stack
							direction="column"
							justifyContent="space-evenly"
							alignItems="center"
							spacing={0.5}
							sx={{ maxHeight: '67vh', overflow: 'auto' }}
						>
							<DndContext
								sensors={sensors}
								onDragStart={(e) => handleDragStart(e, 'link')}
								onDragEnd={handleLinkFolderDragEnd}
								collisionDetection={closestCorners}
							>
								<IntranetColumn folders={linksFolders} type="links" />
							</DndContext>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={4}>
						<HeaderBox>
							<Typography variant="h6" component="h6">
								Dateien
							</Typography>
						</HeaderBox>
						<Stack
							direction="column"
							justifyContent="space-evenly"
							alignItems="center"
							spacing={0.5}
							sx={{ maxHeight: '67vh', overflow: 'auto' }}
						>
							<DndContext
								sensors={sensors}
								onDragStart={(e) => handleDragStart(e, 'file')}
								onDragEnd={handleFileFolderDragEnd}
								collisionDetection={closestCorners}
							>
								<IntranetColumn folders={fileFolders} type="files" />
							</DndContext>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={4}>
						<HeaderBox>
							<Typography variant="h6" component="h6">
								Team Notizen
							</Typography>
						</HeaderBox>
						<Stack
							direction="column"
							justifyContent="space-evenly"
							alignItems="center"
							spacing={0.5}
							sx={{ maxHeight: '67vh', overflow: 'auto' }}
						>
							<IntranetNotesPage notes={notesData} />
						</Stack>
					</Grid>
				</Grid>
				<AddFolderDialog open={openAddFolderDialog} setOpenAddFolderDialog={setOpenAddFolderDialog} />
				<CreateLinkPopup
					openPopup={openPopup}
					setOpenPopup={setOpenPopup}
				/>
			</Paper>
		);
	}
};

