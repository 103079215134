import * as React from "react";
import { useEffect } from "react";
import {
  Datagrid,
  List,
  TextField,
  ReferenceField,
  ReferenceInput,
  Pagination,
  DateField,
  SelectInput,
  required,
  TopToolbar,
  ExportButton,
  useListContext,
  AutocompleteInput,
} from "react-admin";

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />
);
const ListActions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
);
export const InactiveKundenlisteList = () => {
  return (
    <List
      filters={projectFilters}
      filterDefaultValues={{ userType: "inactive" }}
      perPage={50}
      actions={<ListActions />}
      pagination={<ProjectPagination />}
    >
      <Kundenliste />
    </List>
  );
};

export const Kundenliste = () => {
  const { filterValues, showFilter, hideFilter, setFilters } = useListContext();
  useEffect(() => {
    if (filterValues.userType === "inactive") {
      showFilter("inactiveDays");
      if (!filterValues.inactiveDays) {
        setFilters({ ...filterValues, inactiveDays: "30" });
      }
      hideFilter("activeDays");
    } else if (filterValues.userType === "active") {
      showFilter("activeDays");
      if (!filterValues.activeDays) {
        setFilters({ ...filterValues, activeDays: "30" });
      }
      hideFilter("inactiveDays");
    } else {
      hideFilter("activeDays");
      hideFilter("inactiveDays");
    }
  }, [filterValues]);
  return (
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="regionId" reference="regions" label="Region" />
      <DateField source="kunde_seit" label="Kunde Seit" />
      <TextField source="adresse" />
      <TextField source="telefonnummer" />
      <TextField source="ansprechpartner" />
      <TextField source="baustellen" />
      <TextField source="anmerkungen" label="Weitere Anmerkungen" />
      <TextField source="auftragseingang" label="Auftragseingang" />
    </Datagrid>
  );
};

const projectFilters = [
  <ReferenceInput
    source="regionId"
    label="Region"
    reference="regions"
    alwaysOn
  />,
  <SelectInput
    validate={required()}
    source="userType"
    label="User Type"
    alwaysOn
    choices={[
      { id: "active", name: "Active Users" },
      { id: "inactive", name: "Inactive Users" },
    ]}
  />,
  <ReferenceInput
    source="account_manager_id"
    label="Kundenbetreuer"
    alwaysOn
    reference="users"
  >
    <AutocompleteInput optionText="name" label="Kundenbetreuer" />
  </ReferenceInput>,
  <SelectInput
    source="inactiveDays"
    label="Inactive For"
    choices={[
      { id: "30", name: "30 days" },
      { id: "60", name: "60 days" },
      { id: "90", name: "90 days" },
      { id: "120", name: "120 days" },
      { id: "180", name: "180 days" },
      { id: "365", name: "365 days" },
      { id: "730", name: "730 days" },
    ]}
  />,
  <SelectInput
    source="activeDays"
    label="Active In"
    choices={[
      { id: "30", name: "30 days" },
      { id: "60", name: "60 days" },
      { id: "90", name: "90 days" },
      { id: "120", name: "120 days" },
      { id: "180", name: "180 days" },
      { id: "365", name: "365 days" },
      { id: "730", name: "730 days" },
    ]}
  />,
];
