import * as React from "react";

import {
  AutocompleteInput,
  DateInput,
  ExportButton,
  List,
  Pagination,
  ReferenceInput,
  SelectInput,
  TopToolbar,
} from "react-admin";
import LeaderbordList from "./LeaderbordList";

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />
);
const ListActions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
);
export const LeaderbordsList = () => {
  return (
    <List
      filters={trackFilters}
      actions={<ListActions />}
      pagination={<ProjectPagination />}
    >
      <LeaderbordList />
    </List>
  );
};

const trackFilters = [
  <SelectInput
    source="time_period"
    label="Time Period"
    choices={[
      { id: "week", name: "Last Week" },
      { id: "month", name: "Last Month" },
      { id: "quater", name: "Last Quater" },
      { id: "year", name: "Last Year" },
      { id: "this_year", name: `Since ${new Date().getFullYear()}` },
      { id: "custom", name: "Custom Time Period" },
    ]}
    alwaysOn
  />,
  <DateInput source="start_date" />,
  <DateInput source="end_date" />,
  <SelectInput
    source="leaderboardCategories"
    label="Leaderboard Categories"
    choices={[
      { id: "kunden", name: "Kunden" },
      { id: "zustandig", name: "Zuständig" },
      { id: "rechnungen", name: "Rechnungen" },
      { id: "angebote", name: "Angebote" },
      { id: "berichte", name: "Berichte" },
      // { id: "loBerichte", name: "Lo-Berichte" },
    ]}
    alwaysOn
  />,
  <ReferenceInput source="wcategory_id" reference="workerCategories">
    <AutocompleteInput optionText="name" label="Zustanding Category" />
  </ReferenceInput>,
  <ReferenceInput source="regionId" reference="regions" label="Standort" />,
];
