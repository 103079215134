import React from 'react';
import IntranetLink from './IntranetLink';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import DataNotAvailable from './DataNotAvailable';
import { IntranetContext } from './IntranetContext';
import { DragOverlay } from '@dnd-kit/core';
export const IntranetLinks = ({ links = [] }) => {
	const { activeLink, linksFolders } = React.useContext(IntranetContext);

	if (!links || links.length === 0) {
		return <DataNotAvailable>Kein Link hinzugefügt</DataNotAvailable>;
	}
	return (
		<>
			<SortableContext items={links.map((link) => link.link_id)} strategy={verticalListSortingStrategy}>
				{links.map((link) => (
					<IntranetLink key={link.link_id} id={link.link_id} link={link}/>
				))}
			</SortableContext>
			{activeLink && (
				<>
					{activeLink.type === 'link' && (
						<DragOverlay>
							<IntranetLink
								id={activeLink.id}
								link={linksFolders.flatMap(folder => folder.links).find(link => link.link_id === activeLink.id)}
							/>
						</DragOverlay>
					)}
				</>
			)}
		</>
	);
};
