import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import IntranetFolders from './IntranetFolders';
import IntranetFolderFiles from './IntranetFolderFiles';

export default function IntranetColumn({ folders, type }) {
	if (!folders || folders.length === 0) {
		return <Typography>No data available</Typography>;
	}

	return (
		<Box sx={{ width: '100%', height: '100%' }}>
			<SortableContext items={folders.map((folder) => folder.folder_id)} strategy={verticalListSortingStrategy}>
				{type === 'files' ? (
					folders.map((folder) => (
						<IntranetFolderFiles
							key={folder.folder_id}
							id={folder.folder_id}
							name={folder.folder_name}
							files={folder.files}
						/>
					))
				) : (
					folders.map((folder) => (
						<IntranetFolders
							key={folder.folder_id}
							id={folder.folder_id}
							name={folder.folder_name}
							links={folder.links}
						/>
					))
				)}
			</SortableContext>
		</Box>
	);
}
