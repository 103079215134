import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Paper } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { IntranetNotes } from './IntranetNotes';
import { IntranetListItemButtons } from './IntranetLink';
import { useState } from 'react';
import { useEffect } from 'react';
import { IntranetContext } from './IntranetContext';
export default function IntranetNotesRegions({ id, name, notes, isEditing }) {
	const { getRegionsNotesData } = React.useContext(IntranetContext);
	const [regionNotes, setRegionNotes] = useState([]);
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

	useEffect(() => {
		let regionData = getRegionsNotesData(id);
		setRegionNotes(regionData.notes)
	}, [id, getRegionsNotesData]);

	const style = {
		transform: CSS.Transform.toString(transform),
		transition
	};

	return (
		< Paper
			ref={setNodeRef}
			style={style}
			{...attributes}
			elevation={0}
			sx={{ padding: '10px 0', paddingBottom: 0, width: '100%' }
			}
		>
			<Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="7px">
				<Typography
					variant="h6"
					component="h6"
					noWrap
					display="flex"
					alignItems="center"
					sx={{ fontSize: '1.1rem' }}
				>
					Region {name}
				</Typography>
				<Box marginRight={'10px'}>
					<IntranetListItemButtons listeners={listeners} notShowDeleteIcon={true} />
				</Box>
			</Box>
			<IntranetNotes
				notes={regionNotes}
			/>
		</ Paper>
	);
}
