import * as React from "react";
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  PasswordInput,
  UrlField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  Create,
  required,
  CloneButton,
  ListButton,
  TopToolbar,
  Pagination,
  ReferenceInput,
} from "react-admin";
import WorkButton from "./WorkButton";

const ProjectPagination = (props) => (
  <Pagination
    rowsPerPageOptions={[10, 20, 25, 50, 100, 500, 1000]}
    {...props}
  />
);

export const WorkList = () => (
  <List
    filters={workListFilters}
    perPage={20}
    pagination={<ProjectPagination />}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <WorkButton source="id" />
      <EditButton />
    </Datagrid>
  </List>
);

const ProjectEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="< Zurück" />
  </TopToolbar>
);

export const WorkEdit = (props) => (
  <Edit title="Edit Worklists" actions={<ProjectEditActions />}>
    <SimpleForm>
      <TextInput readOnly source="id" />
      <TextInput label="Worklists Name" source="name" validate={required()} />
      <ReferenceInput source="user_id" reference="users" validate={required()}>
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const WorkCreate = (props) => (
  <Create title="Add New Worklists" {...props} actions={<ProjectEditActions />}>
    <SimpleForm>
      <TextInput label="Worklists Name" source="name" validate={required()} />
      <ReferenceInput source="user_id" reference="users" validate={required()}>
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const workListFilters = [
  <TextInput label="Search" source="address_q" placeholder="Search by Address/Street" alwaysOn resettable/>,
];