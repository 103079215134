import * as React from "react";
import { Layout, Sidebar, Menu } from "react-admin";
import CustomAppBar from "./CustomAppBar";
import ErrorIcon from '@mui/icons-material/Error';
import MenuMui from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, IconButton } from '@mui/material';
import config from '../config';

const apiUrl = config.apiUrl;


const CustomButton = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleErrorClick = async() => {
    try {
      const url = `${apiUrl}?section=errorlink&action=get&id=1`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });

      if (!response.ok) {
        console.error("Error:", response.statusText);
        return;
      }

      const data = await response.json();
      window.open(data.address, "_blank");
    } catch (error) {
      console.log("🚀 ~ MyLayout ~ CustomButton ~ handleErrorClick ~ error:", error)
    }
    handleClose();
  };

  return (
    <Box 
      sx={{ position: 'fixed', left: '3px', bottom: '10px' }}
    >
      <IconButton size="large" color="dark"
        id="basic-button"
        onClick={handleClick}
        variant="contained"
      >
        <ErrorIcon fontSize="inherit" /> 
      </IconButton>
      <MenuMui
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleErrorClick}>Fehler Melden</MenuItem>
      </MenuMui>
    </Box>
  );
};

const MySidebar = (props) => (
  <Sidebar {...props} >
    <Box sx={{ overflowY: 'auto', height: 'calc(100vh - 100px)', paddingBottom: '20px' }}>
      <Menu {...props} />
    </Box>
    <CustomButton />
  </Sidebar>
);

const MyLayout = (props) => (
  <Layout
    {...props}
    appBar={CustomAppBar}
    sidebar={MySidebar}
    sx={{ "& .RaLayout-appFrame": { marginTop: "90px" } }}
  />
);

export default MyLayout;
