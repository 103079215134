import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Paper } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { IntranetFiles } from './IntranetFiles';
import { IntranetListItemButtons } from './IntranetLink';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { IntranetContext } from './IntranetContext';
import config from '../../config';
import ConfirmationDialog from './ConfirmationDialog';
import AddFolderDialog from './AddFolderDialog';

const apiUrl = config.apiUrl;

export default function IntranetFolderFiles({ id, name }) {
	const { getFileFolderData, handleFolderDelete } = React.useContext(IntranetContext);
	const [folderFiles, setFolderFiles] = useState([]);
	const [confirmDialogToggle, setConfirmDialogToggle] = useState(false);
	const [openAddFolderDialog, setOpenAddFolderDialog] = useState(false);

	useEffect(
		() => {
			const fileFolderData = getFileFolderData(id);
			setFolderFiles(fileFolderData.files);
		},
		[id, getFileFolderData]
	);

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

	const style = {
		transition,
		transform: CSS.Transform.toString(transform)
	};

	const handleDeleteFileFolder = async () => {
		try {
			const response = await fetch(apiUrl + '?section=intranet&action=deleteFolder&id=' + id, {
				method: 'POST',
			});
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const result = await response.json();
			handleFolderDelete(id, 'fileFolder');
			console.log('fileFolder deleted succesfully', result);
		} catch (err) {
			console.log('fileFolder not deleted');
		}
	}

		return (
			<>
				<Paper
					ref={setNodeRef}
					style={style}
					{...attributes}
					elevation={0}
					sx={{ padding: '10px 0', paddingBottom: 0, width: '100%' }}
				>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Typography variant="p" component="p" noWrap display="flex" alignItems="center">
							{name}
						</Typography>
						<Box marginRight={'10px'}>
							<IntranetListItemButtons onClickDelete={() => setConfirmDialogToggle((prevState) => !prevState)} onClickEdit={() => setOpenAddFolderDialog(true)} listeners={listeners} />
							{confirmDialogToggle && <ConfirmationDialog open={confirmDialogToggle} onCancel={() => setConfirmDialogToggle(false)} onConfirm={handleDeleteFileFolder} text={'folder'} />}
						</Box>
					</Box>
					<SortableContext
						items={folderFiles.map((file) => file.file_id)}
						strategy={verticalListSortingStrategy}
					>
						<IntranetFiles files={folderFiles} />
					</SortableContext>
				</Paper>
				{openAddFolderDialog === true && <AddFolderDialog open={openAddFolderDialog} setOpenAddFolderDialog={setOpenAddFolderDialog} mode={'edit'} selectedFolderName={name} selectedfolderType={'2'} selectedFolderId={id} />}
			</>
		);
}
