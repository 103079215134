import React, { useState, useCallback, useEffect } from "react";
import {
  Dialog,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FolderSelect from "./FolderSelect";
import RegionSelect from "./RegionSelect";
import TextAreaPopup from "./TextAreaPopup.js";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import { useContext } from "react";
import { IntranetContext } from "./IntranetContext.js";
import CheckIcon from "@mui/icons-material/Check";
import config from "../../config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
} from "ckeditor5";
import { List } from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import "ckeditor5-premium-features/ckeditor5-premium-features.css";

const apiUrl = config.apiUrl;

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
const ALLOWED_FILE_EXTENSIONS = [
  "doc",
  "docx",
  "pdf",
  "txt",
  "ppt",
  "pptx",
  "xls",
  "xlsx",
  "csv",
];

const TextInput = styled(TextField)({
  // Custom styles
});

const DropzoneArea = styled(Box)(({ theme }) => ({
  border: `2px dashed gray`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  textAlign: "center",
  backgroundColor: theme.palette.background.default,
  transitionDuration: "0.3s",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    border: `2px dashed ${theme.palette.primary.main}`,
    transform: `scale(1.01, 1.01)`,
  },
}));

const FilePreviewContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0px 10px",
  gap: theme.spacing(2),
}));

const CreateLinkPopup = ({
  openPopup,
  setOpenPopup,
  mode = "create",
  identifierValue = "",
  selectedFolderValue = "",
  linkNameValue = "",
  linkAddressValue = "",
  selectedRegionsValue = [],
  fileOriginalNameValue = "",
  fileNameValue = "",
  selectedFileValue = null,
  currentLinkId = "",
  currentFileId = "",
  currentNoteId = "",
  currentColor = "",
  currentText = "",
  currentHeadline = "",
}) => {
  const {
    linksFolders,
    setFileFolders,
    fileFolders,
    setLinksFolders,
    setNotesData,
    notesData,
  } = useContext(IntranetContext);
  const [identifier, setIdentifier] = useState(identifierValue);
  const [dialogContent, setDialogContent] = useState(null);
  const [selectedFile, setSelectedFile] = useState(selectedFileValue);
  const [fileName, setFileName] = useState(fileOriginalNameValue);
  const [errorMessage, setErrorMessage] = useState("");
  const [headline, setHeadline] = useState(currentHeadline);
  const [text, setText] = useState(currentText);
  const [selectedColor, setSelectedColor] = useState(currentColor);
  const [selectedFolder, setSelectedFolder] = useState(selectedFolderValue);
  const [linkName, setLinkName] = useState(linkNameValue);
  const [linkAddress, setLinkAddress] = useState(linkAddressValue);
  const [region, setRegion] = useState(selectedRegionsValue);
  const [isDisabled, setIsDisabled] = useState(false);
  // const [loading, setLoading] = useState(false);
  // function handleLoadingButtonClick() {
  //   setLoading(true);
  // }
  const handleClick = (color) => {
    setSelectedColor(color);
  };

  function handleClose() {
    setOpenPopup(false);
    setIdentifier("");
    setDialogContent(null);
    setSelectedFile(null);
    setFileName("");
    setErrorMessage("");
    setHeadline("");
    setText("");
    setSelectedColor("");
    setSelectedFolder("");
    setLinkName("");
    setLinkAddress("");
    setRegion([]);
  }

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("File size exceeds the 10MB limit.");
        setSelectedFile(null);
        setFileName("");
      } else if (!ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
        setErrorMessage(
          "Invalid file type. Allowed types are: doc, docx, pdf, txt, ppt, pptx, xls, xlsx, csv"
        );
        setSelectedFile(null);
        setFileName("");
      } else {
        setSelectedFile(file);
        setFileName(file.name);
        setErrorMessage("");
      }
    }
  }, []);

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setFileName("");
  };

  const handleSave = async () => {
    setIsDisabled(true);
    const linkUrl =
      mode === "create"
        ? `${apiUrl}?section=intranet&action=createLink`
        : `${apiUrl}?section=intranet&action=editLink&id=${currentLinkId}`;
    const fileUrl =
      mode === "create"
        ? `${apiUrl}?section=intranet&action=createFile`
        : `${apiUrl}?section=intranet&action=editFile&id=${currentFileId}`;
    const noteUrl =
      mode === "create"
        ? `${apiUrl}?section=intranet&action=createNote`
        : `${apiUrl}?section=intranet&action=createNote&id=${currentNoteId}`;

    if (!region) {
      setErrorMessage("Please select a Region");
      return;
    }

    const formData = new FormData();
    formData.append("folder_id", selectedFolder);
    formData.append("region_ids", JSON.stringify(region));
    if (identifier === "note") {
      const noteData = new FormData();
      noteData.append("region_id", region);
      noteData.append("headline", headline);
      noteData.append("text", text);
      noteData.append("color", selectedColor);

      try {
        const response = await fetch(noteUrl, {
          method: "POST",
          body: noteData,
        });

        const result = await response.json();

        if (response.ok) {
          const newNotesData = newNotesDataArray(result, region, currentNoteId);
          setNotesData(newNotesData);
          handleClose();
        } else {
          setErrorMessage(result.errMsg || "Failed to create note");
        }
      } catch (error) {
        console.error("Error creating note:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");
      } finally {
        setIsDisabled(false);
      }
    } else if (identifier === "file") {
      if (!selectedFile && mode !== "edit") {
        setErrorMessage("Please select a file");
        return;
      }
      if (mode !== "edit") {
        formData.append("name", fileName);
        formData.append("file", selectedFile);
      } else {
        formData.append("orginal_name", fileName);
      }
      try {
        const response = await fetch(fileUrl, {
          method: "POST",
          body: formData,
        });

        const result = await response.json();

        if (response.ok && !result.errMsg) {
          let newFilesData = newFilesDataArray(result);
          setFileFolders(newFilesData);
          setFileName("");
          setSelectedFile(null);
          handleClose();
        } else {
          setErrorMessage(result.errMsg || "Failed to create file");
        }
      } catch (error) {
        console.error("Error creating file:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");
      } finally {
        setIsDisabled(false);
      }
    } else if (identifier === "link") {
      formData.append("address", linkAddress);
      formData.append("name", linkName);

      try {
        const response = await fetch(linkUrl, {
          method: "POST",
          body: formData,
        });

        const result = await response.json();

        if (response.ok) {
          let newLinksData = newLinksDataArray(result);
          setLinksFolders(newLinksData);
          handleClose();
        } else {
          setErrorMessage(result.errMsg || "Failed to create link");
        }
      } catch (error) {
        console.error("Error creating link:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");
      } finally {
        setIsDisabled(false);
      }
    }
  };

  const newLinksDataArray = (result) => {
    let newLinksData = null;
    switch (mode) {
      case "create":
        newLinksData = linksFolders.map((folder) => {
          if (folder.folder_id === selectedFolder) {
            return {
              ...folder,
              links: [
                ...folder.links,
                {
                  link_id: result.id,
                  link_name: result.name,
                  link_address: result.address,
                  link_region_id: region,
                  selected_folder_id: selectedFolder,
                  link_display_order: null,
                },
              ],
            };
          }
          return folder;
        });
        break;
      case "edit":
        let previousFolderId = null;
        linksFolders.forEach((folder) => {
          if (folder.links.some((link) => link.link_id === currentLinkId)) {
            previousFolderId = folder.folder_id;
          }
        });
        if (previousFolderId && previousFolderId !== selectedFolder) {
          newLinksData = linksFolders.map((folder) => {
            if (folder.folder_id === previousFolderId) {
              return {
                ...folder,
                links: folder.links.filter(
                  (link) => link.link_id !== currentLinkId
                ),
              };
            } else if (folder.folder_id === selectedFolder) {
              return {
                ...folder,
                links: [
                  ...folder.links,
                  {
                    link_id: currentLinkId,
                    link_name: result.name,
                    link_address: result.address,
                    selected_folder_id: selectedFolder,
                    link_region_id: region,
                    link_display_order: null,
                  },
                ],
              };
            }
            return folder;
          });
        } else {
          newLinksData = linksFolders.map((folder) => {
            if (folder.folder_id === selectedFolder) {
              return {
                ...folder,
                links: folder.links.map((link) => {
                  if (link.link_id === currentLinkId) {
                    return {
                      ...link,
                      link_name: result.name,
                      link_address: result.address,
                      link_region_id: region,
                      link_display_order: null,
                    };
                  }
                  return link;
                }),
              };
            }
            return folder;
          });
        }
        break;
      default:
        break;
    }
    return newLinksData;
  };

  const newFilesDataArray = (result) => {
    let newFilesData = null;

    switch (mode) {
      case "create":
        newFilesData = fileFolders.map((folder) => {
          if (folder.folder_id === selectedFolder) {
            return {
              ...folder,
              files: [
                ...folder.files,
                {
                  file_id: result.id,
                  file_name: result.name,
                  file_orginal_name: result.orginal_name,
                  file_region_id: result.region_id,
                  selected_folder_id: selectedFolder,
                  file_display_order: null,
                },
              ],
            };
          }
          return folder;
        });
        break;
      case "edit":
        let previousFolderId = null;
        fileFolders.forEach((folder) => {
          if (folder.files.some((file) => file.file_id === currentFileId)) {
            previousFolderId = folder.folder_id;
          }
        });
        if (previousFolderId && previousFolderId !== selectedFolder) {
          newFilesData = fileFolders.map((folder) => {
            if (folder.folder_id === previousFolderId) {
              return {
                ...folder,
                files: folder.files.filter(
                  (file) => file.file_id !== currentFileId
                ),
              };
            } else if (folder.folder_id === selectedFolder) {
              return {
                ...folder,
                files: [
                  ...folder.files,
                  {
                    file_id: result.id,
                    file_name: result.name,
                    file_orginal_name: result.orginal_name,
                    file_region_id: region,
                    selected_folder_id: selectedFolder,
                    file_display_order: null,
                  },
                ],
              };
            }
            return folder;
          });
        } else {
          // Folder is the same, just update the file values
          newFilesData = fileFolders.map((folder) => {
            if (folder.folder_id === selectedFolder) {
              return {
                ...folder,
                files: folder.files.map((file) => {
                  if (file.file_id === currentFileId) {
                    return {
                      ...file,
                      file_name: result.name,
                      file_orginal_name: result.orginal_name,
                      file_region_id: region,
                      file_display_order: null,
                      selected_folder_id: selectedFolder,
                    };
                  }
                  return file;
                }),
              };
            }
            return folder;
          });
        }
        break;

      default:
        break;
    }
    return newFilesData;
  };

  const newNotesDataArray = (
    result,
    selectedRegionId,
    currentNoteId = null
  ) => {
    let newNotesData = null;

    switch (mode) {
      case "create":
        newNotesData = notesData.map((region) => {
          if (region.region_id === selectedRegionId) {
            return {
              ...region,
              notes: [
                ...region.notes,
                {
                  note_id: result.id,
                  note_color: result.color,
                  note_display_order: null,
                  note_headline: result.headline,
                  selected_region_id: selectedRegionId,
                  note_text: result.text,
                },
              ],
            };
          }
          return region;
        });
        break;
      case "edit":
        let previousRegionId = null;
        notesData.forEach((region) => {
          if (region.notes.some((note) => note.note_id === currentNoteId)) {
            previousRegionId = region.region_id;
          }
        });

        if (previousRegionId && previousRegionId !== selectedRegionId) {
          newNotesData = notesData.map((region) => {
            if (region.region_id === previousRegionId) {
              return {
                ...region,
                notes: region.notes.filter(
                  (note) => note.note_id !== currentNoteId
                ),
              };
            } else if (region.region_id === selectedRegionId) {
              return {
                ...region,
                notes: [
                  ...region.notes,
                  {
                    note_id: currentNoteId,
                    note_color: result.color,
                    note_display_order: null,
                    note_headline: result.headline,
                    selected_region_id: selectedRegionId,
                    note_text: result.text,
                  },
                ],
              };
            }
            return region;
          });
        } else {
          newNotesData = notesData.map((region) => {
            if (region.region_id === selectedRegionId) {
              return {
                ...region,
                notes: region.notes.map((note) => {
                  if (note.note_id === currentNoteId) {
                    return {
                      ...note,
                      note_color: result.color,
                      note_headline: result.headline,
                      note_text: result.text,
                      note_display_order: null,
                      selected_region_id: selectedRegionId,
                    };
                  }
                  return note;
                }),
              };
            }
            return region;
          });
        }
        break;
      default:
        break;
    }

    return newNotesData;
  };

  return (
    <Dialog open={openPopup} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Neuen Eintrag Hinzufügen
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Eintrag Art"
          select
          fullWidth
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
          margin="dense"
          variant="outlined"
          disabled={mode === "edit"}
          sx={{ marginBottom: "20px" }}
        >
          <MenuItem value="link">Link</MenuItem>
          <MenuItem value="file">Datei</MenuItem>
          <MenuItem value="note">Notiz</MenuItem>
        </TextField>
        {identifier === "note" && (
          <CreateNote
            headline={headline}
            setHeadline={setHeadline}
            text={text}
            setText={setText}
            handleClick={handleClick}
            selectedColor={selectedColor}
            region={region}
            setRegion={setRegion}
          />
        )}
        {identifier === "link" && (
          <CreateLink
            linkName={linkName}
            linkAddress={linkAddress}
            linksFolders={linksFolders}
            setLinkName={setLinkName}
            setLinkAddress={setLinkAddress}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            region={region}
            setRegion={setRegion}
          />
        )}
        {identifier === "file" && (
          <CreateFile
            mode={mode}
            onDrop={onDrop}
            fileFolders={fileFolders}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            fileName={fileName}
            fileNameValue={fileNameValue}
            handleFileNameChange={handleFileNameChange}
            handleRemoveFile={handleRemoveFile}
            errorMessage={errorMessage}
            selectedFile={selectedFile}
            region={region}
            setRegion={setRegion}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleSave}
          color="primary"
          startIcon={<SaveIcon />}
          disabled={isDisabled}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateLinkPopup;

export const CreateLink = ({
  linkName,
  linkAddress,
  linksFolders,
  selectedFolder,
  setSelectedFolder,
  setLinkName,
  setLinkAddress,
  region = [],
  setRegion,
}) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="left" gap="20px">
      <FolderSelect
        folders={linksFolders}
        selectedValue={selectedFolder}
        onChange={(e) => setSelectedFolder(e.target.value)}
      />
      <TextInput
        id="link-name"
        label="Link Name"
        variant="outlined"
        fullWidth
        value={linkName}
        onChange={(e) => setLinkName(e.target.value)}
      />
      <TextInput
        id="link-address"
        label="Link Address"
        variant="outlined"
        fullWidth
        value={linkAddress}
        onChange={(e) => setLinkAddress(e.target.value)}
      />
      <RegionSelect
        selectedRegionValue={region}
        onChange={(selectedRegionId) => setRegion(selectedRegionId)}
      />
    </Box>
  );
};

export const CreateFile = ({
  mode,
  onDrop,
  fileFolders,
  selectedFolder,
  setSelectedFolder,
  fileName,
  fileNameValue,
  handleFileNameChange,
  handleRemoveFile,
  errorMessage,
  selectedFile,
  region = [],
  setRegion,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });
  return (
    <Box display="flex" flexDirection="column" justifyContent="left" gap="20px">
      <FolderSelect
        folders={fileFolders}
        selectedValue={selectedFolder}
        onChange={(e) => setSelectedFolder(e.target.value)}
      />
      <TextInput
        id="file-name"
        label="Datei Name"
        variant="outlined"
        fullWidth
        value={fileName}
        onChange={(e) => handleFileNameChange(e)}
      />
      <Box>
        {mode !== "edit" && (
          <DropzoneArea {...getRootProps()}>
            <input {...getInputProps()} />
            <CloudUploadIcon sx={{ fontSize: 60, color: "primary.main" }} />
            {isDragActive ? (
              <Typography variant="body1" color="primary">
                Drag 'n' drop here...
              </Typography>
            ) : (
              <Typography variant="body1">
                Drag 'n' drop zum Upload oder klicke hier, um eine Datei
                auszuwählen. Akzeptierte Dateien: .pdf, .docx, .txt (Max.
                Dateigröße 10MB)
              </Typography>
            )}
          </DropzoneArea>
        )}
        {selectedFile && (
          <Box mt={2}>
            <Typography variant="subtitle1">Selected File:</Typography>
            <FilePreviewContainer>
              <Typography variant="body2">{fileName}</Typography>
              <IconButton size="small" onClick={handleRemoveFile}>
                <ClearIcon color="error" size="small" />
              </IconButton>
            </FilePreviewContainer>
          </Box>
        )}
        {errorMessage && (
          <Box mt={2}>
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
          </Box>
        )}
      </Box>
      <RegionSelect
        selectedRegionValue={region}
        onChange={(selectedRegionId) => setRegion(selectedRegionId)}
      />
    </Box>
  );
};

export const CreateNote = ({
  headline,
  setHeadline,
  text,
  setText,
  handleClick,
  selectedColor,
  region = [],
  setRegion,
}) => {
  const handleEditorChange = (event, editor) => {
    setText(editor.getData());
  };
  return (
    <Box display="flex" flexDirection="column" justifyContent="left" gap="20px">
      <TextInput
        id="note-headline"
        label="Notiz Titel"
        variant="outlined"
        fullWidth
        value={headline}
        onChange={(e) => setHeadline(e.target.value)}
      />
      <CKEditor
        editor={ClassicEditor}
        onChange={handleEditorChange}
        config={{
          minHeight: "200px",
          placeholder: "Start writing here",
          toolbar: {
            items: [
              "undo",
              "redo",
              "|",
              "bold",
              "italic",
              "|",
              "bulletedList",
              "numberedList",
            ],
          },
          plugins: [Bold, Essentials, Italic, Mention, Paragraph, Undo, List],
          initialData: text,
        }}
      />
      <label name="note color">Notiz Farbe</label>
      <Stack direction="row" spacing={1}>
        {/* Color selection buttons */}
        <IconButton
          onClick={() => handleClick("white")}
          sx={{
            backgroundColor: "white",
            borderRadius: "50px",
            padding: "15px 15px",
            border: "1px solid gray",
          }}
        >
          {selectedColor === "white" && (
            <CheckIcon
              sx={{
                color: "gray",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </IconButton>
        <IconButton
          onClick={() => handleClick("lightpink")}
          sx={{
            backgroundColor: "lightpink",
            borderRadius: "50px",
            padding: "15px 15px",
            border: "1px solid gray",
          }}
        >
          {selectedColor === "lightpink" && (
            <CheckIcon
              sx={{
                color: "gray",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </IconButton>
        <IconButton
          onClick={() => handleClick("lightgoldenrodyellow")}
          sx={{
            backgroundColor: "lightgoldenrodyellow",
            borderRadius: "50px",
            padding: "15px 15px",
            border: "1px solid gray",
          }}
        >
          {selectedColor === "lightgoldenrodyellow" && (
            <CheckIcon
              sx={{
                color: "gray",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </IconButton>
        <IconButton
          onClick={() => handleClick("lightgreen")}
          sx={{
            backgroundColor: "lightgreen",
            borderRadius: "50px",
            padding: "15px 15px",
            border: "1px solid gray",
          }}
        >
          {selectedColor === "lightgreen" && (
            <CheckIcon
              sx={{
                color: "gray",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </IconButton>
        <IconButton
          onClick={() => handleClick("lightblue")}
          sx={{
            backgroundColor: "lightblue",
            borderRadius: "50px",
            padding: "15px 15px",
            border: "1px solid gray",
          }}
        >
          {selectedColor === "lightblue" && (
            <CheckIcon
              sx={{
                color: "gray",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </IconButton>
        <IconButton
          onClick={() => handleClick("lightgray")}
          sx={{
            backgroundColor: "lightgray",
            borderRadius: "50px",
            padding: "15px 15px",
            border: "1px solid gray",
          }}
        >
          {selectedColor === "lightgray" && (
            <CheckIcon
              sx={{
                color: "gray",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </IconButton>
      </Stack>
      <RegionSelect
        type="note"
        selectedRegionValue={region}
        onChange={(selectedRegionId) => setRegion(selectedRegionId)}
      />
    </Box>
  );
};
