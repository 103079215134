import React, { createContext, useState } from 'react';

// Create the context
export const IntranetContext = createContext();

// Context provider component
export const IntranetProvider = ({ children }) => {
    const [linksFolders, setLinksFolders] = useState([]);
    const [fileFolders, setFileFolders] = useState([]);
    const [notesData, setNotesData] = useState([]);
    const [regionsData, setRegionsData] = useState([]);
    const [activeLink, setActiveLink] = useState({
        id: null,
        type: null,
    });
    const [activeFile, setActiveFile] = useState({
        id: null,
        type: null,
    });
    const [activeNote, setActiveNote] = useState({
        id: null,
        type: null,
    });

    const handleDelete = (id, type) => {
        switch (type) {
            case 'link':
                setLinksFolders((prevLinksFoldersData) => {
                    let updatedData = prevLinksFoldersData.map((folder) => {
                        if (folder.links.some((link) => link.link_id === id)) {
                            return {
                                ...folder,
                                links: folder.links.filter((link) => link.link_id !== id),
                            };
                        }
                        return folder;
                    });

                    return updatedData;
                });
                break;
            case 'file':
                setFileFolders((prevFileFoldersData) => {
                    let updatedData = prevFileFoldersData.map((folder) => {
                        if (folder.files.some((file) => file.file_id === id)) {
                            return {
                                ...folder,
                                files: folder.files.filter((file) => file.file_id !== id),
                            };
                        }
                        return folder;
                    });

                    return updatedData;
                });
                break;
            case 'note':
                setNotesData((prevNotesData) => {
                    let updatedNotesData = prevNotesData.map((region) => {
                        if (region.notes.some((note) => note.note_id === id)) {
                            return {
                                ...region,
                                notes: region.notes.filter((note) => note.note_id !== id),
                            };
                        }
                        return region;
                    });

                    return updatedNotesData;
                });
                break;
            default:
                break;
        }
    };
    const handleFolderDelete = (id, type) => {
        switch (type) {
            case 'linkFolder':
                setLinksFolders((prevFolders) => prevFolders.filter((folder) => folder.folder_id !== id));
                break;
            case 'fileFolder':
                setFileFolders((prevFolders) => prevFolders.filter((folder) => folder.folder_id !== id));
                break;
            default:
                break;
        }
    };

    const getRegionsNotesData = (id) => {
        return notesData.find((region) => region.region_id === id)
    }
    const getLinksFolderData = (id) => {
        return linksFolders.find((folder) => folder.folder_id === id)
    }
    const getFileFolderData = (id) => {
        return fileFolders.find((folder) => folder.folder_id === id)
    }

    return (
        <IntranetContext.Provider value={{ 
            linksFolders, 
            setLinksFolders, 
            fileFolders, 
            setFileFolders, 
            notesData, 
            setNotesData, 
            regionsData, 
            setRegionsData, 
            handleDelete, 
            getRegionsNotesData, 
            getLinksFolderData, 
            getFileFolderData,
            handleFolderDelete,
            activeLink,
            setActiveLink,
            activeFile,
            setActiveFile,
            activeNote,
            setActiveNote,
            }}>
            {children}
        </IntranetContext.Provider>
    );
};
