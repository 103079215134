import React, { useContext, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Typography } from '@mui/material';
import { IntranetListItemButtons } from './IntranetLink'; 
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Item } from './IntranetLink';
import { IntranetContext } from './IntranetContext';
import config from '../../config';
import ConfirmationDialog from './ConfirmationDialog';
import Tooltip from '@mui/material/Tooltip';
import CreateLinkPopup from './CreateLinkPopup';
import PreviewFile from './PreviewFile';
import { Link } from '@mui/material';
import { Button } from '@mui/material';

const apiUrl = config.apiUrl;
const apiBaseUrl = config.apiBaseUrl;

export const FileItem = ({ id, file }) => {
	const { handleDelete } = useContext(IntranetContext);
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
	const [confirmDialogToggle, setConfirmDialogToggle] = useState(false);
	const [openPopup, setOpenPopup] = useState(false);
	const [openPreviewFile, setOpenPreviewFile] = useState(false);
	const style = {
		transform: CSS.Transform.toString(transform),
		transition
	};	

	const handleDeleteFile = async () => {
		try {
			const response = await fetch(apiUrl + '?section=intranet&action=deleteFile&id=' + id, {
				method: 'POST',
			});
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const result = await response.json();
			handleDelete(id, 'file');
			console.log('file deleted succesfully', result);
		} catch (err) {
			console.log('file not deleted');
		}
	}
	const handleDownloadFile = async () => {
		try {
			const response = await fetch(apiUrl + '?section=intranet&action=downloadFile&id=' + id, {
				method: 'POST',
			});
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}			const blob = await response.blob();
			const blobUrl = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = blobUrl;
			link.setAttribute('download', file.file_name);
			document.body.appendChild(link);
			link.click();
			URL.revokeObjectURL(blobUrl);
			link.remove();
			console.log('file downloaded succesfully');
		} catch (err) {
			console.log('file not downloaded');
		}
	}
	return (
		<>
			<Box ref={setNodeRef} style={style} {...attributes} key={id}>
				<Item>
					<Button sx={{
						backgroundColor: 'transparent',
						border: 'none',
						boxShadow: 'none',
						padding: 0,
						minWidth: 0,
						color: 'inherit',
						'&:hover': {
						backgroundColor: 'transparent', 
						color: 'inherit', 
						},
						flex: '1', padding: '5px 8px',
						justifyContent: 'left'
					}} onClick={() => { setOpenPreviewFile(true) }} >
							<Box display="flex" flex='1' alignItems="center" gap="15px" maxWidth='300px'>
								<Box sx={{ color: '#808080b8' }} display="flex" alignItems="center">
									<DescriptionOutlinedIcon />
								</Box>
								<Typography variant="p" noWrap >
									<Tooltip title={file.file_orginal_name} placement="bottom" arrow>
										<span>{file.file_orginal_name}</span>
									</Tooltip>
								</Typography>
							</Box>
					</Button>
					<Box sx={{padding: '5px 8px'}}>
						<IntranetListItemButtons onClickDelete={() => setConfirmDialogToggle((prevState) => !prevState)} onClickEdit={() => setOpenPopup(true)} identifier={'file'} fileExtension={file.file_name.split('.').pop().toLowerCase()}listeners={listeners} />
						<ConfirmationDialog open={confirmDialogToggle} onCancel={() => setConfirmDialogToggle(false)} onConfirm={handleDeleteFile} text={'file'} />

					</Box>		
				</Item>
			</Box>
			{openPreviewFile === true && <PreviewFile fileUrl={`${apiBaseUrl}/public/intranet_files/${file.file_name}`} name={file.file_name} open={openPreviewFile} setOpen={setOpenPreviewFile} handleDownloadFile={handleDownloadFile} /> }
			{openPopup === true && <CreateLinkPopup
				openPopup={openPopup}
				setOpenPopup={setOpenPopup}
				mode='edit'
				identifierValue='file'
				currentFileId={id}
				selectedFolderValue={file.selected_folder_id}
				fileNameValue={file.file_name}
				fileOriginalNameValue={file.file_orginal_name}
				selectedFileValue={file.id}
				selectedRegionsValue={file.file_region_id}
			/>}
		</>
	);
};

export default FileItem;
