import React, { useState } from "react";
import {
  Box,
  Button,
  CardContent,
  Modal,
  Grid,
  IconButton,
  Typography,
  Input,
  TextField,
  CircularProgress,
} from "@mui/material";
import {
  Description as DescriptionIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useNotify, useRefresh } from "react-admin";

const style = {
  position: "absolute",
  top: "10%",
  left: 0,
  right: 0,
  margin: "auto",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const CreateFile = ({ apiUrl, open, handleClose, filesizes }) => {
  const params = useParams();
  const notify = useNotify();
  const refresh = useRefresh();
  const controller = new AbortController();
  const signal = controller.signal;
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, SetSelectedFile] = useState([]);
  const [fileUpload, setFileUpload] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const isImageFile = (filename) => {
    const imageExtensions = [".jpg", ".jpeg", ".png"];
    return imageExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
  };
  const generateUniqueId = () => {
    return `id-${new Date().getTime()}-${Math.floor(Math.random() * 10000)}`;
  };
  const inputChange = (e) => {
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (file.size > 41943040) {
        notify(
          "Please select the file again. The size should not exceed 40MB.",
          {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );
        if (document.getElementById("fileupload").value) {
          document.getElementById("fileupload").value = "";
        }
        return;
      }
      let reader = new FileReader();
      reader.onloadstart = () => {
        setUploadProgress(0);
      };
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setUploadProgress(progress);
        }
      };
      reader.onloadend = ((file) => {
        return (event) => {
          let uniqueid = generateUniqueId();
          file.id = uniqueid;
          setFileUpload((prevFiles) => [...prevFiles, file]);
          SetSelectedFile((prevValue) => [
            ...prevValue,
            {
              id: uniqueid,
              filename: file.name,
              filetype: file.type,
              fileimage: event.target.result,
              datetime: file.lastModifiedDate.toLocaleString("en-IN"),
              filesize: filesizes(file.size),
            },
          ]);
        };
      })(file);
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const removeFile = (id) => {
    const filteredFiles = selectedFile.filter((file) => file.id !== id);
    SetSelectedFile(filteredFiles);
    const filteredFileUpload = Array.from(fileUpload).filter(
      (file) => file.id !== id
    );
    setFileUpload(filteredFileUpload);
    // Reset the input value to allow re-selection of the same file
    if (document.getElementById("fileupload").value) {
      document.getElementById("fileupload").value = "";
    }
  };
  const handleFileDescription = (e, id) => {
    const updatedFileUpload = [...fileUpload];
    const fileIndex = updatedFileUpload.findIndex((file) => file.id === id);
    if (fileIndex !== -1) {
      updatedFileUpload[fileIndex].description = e.target.value;
      setFileUpload(updatedFileUpload);
    }
  };
  const fileUploadSubmit = async (e) => {
    setIsUploading(true);
    e.preventDefault();
    if (fileUpload.length === 0) {
      notify("No file selected", {
        type: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      return;
    }
    let isFileSizeGreater = fileUpload.some((file) => {
      if (file.size > 41943040) {
        notify(
          "Please select the file again. The size should not exceed 40MB.",
          {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );
        SetSelectedFile([]);
        setFileUpload([]);
        if (document.getElementById("fileupload").value) {
          document.getElementById("fileupload").value = "";
        }
        return true;
      } else {
        return false;
      }
    });
    if (!isFileSizeGreater) {
      const formData = new FormData();
      fileUpload.forEach((file) => {
        formData.append("file[]", file);
        formData.append("descriptions[]", file.description || "");
      });
      formData.append("id", params.id);
      try {
        const response = await fetch(
          `${apiUrl}?section=uploads&action=uploadFiles&id=${params.id}`,
          {
            method: "POST",
            body: formData,
            signal: signal, // Pass the signal to the fetch request
          }
        );
        if (!response.ok) {
          notify("Upload failed: " + response.statusText, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setIsUploading(false);
          return;
        }
        const result = await response.json();
        if (result.errMsg) {
          notify(result.errMsg, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setIsUploading(false);
          return;
        }
        notify(result.msg, {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        refresh();
        SetSelectedFile([]);
        setFileUpload([]);
        handleClose();
      } catch (error) {
        console.log(error);
        if (error.name === "AbortError") {
          notify("Upload cancelled", {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          notify("Upload failed ", {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      }
    }
    setIsUploading(false);
  };
  const cancelUpload = () => {
    controller.abort(); // This will cancel the ongoing fetch request
    console.log("Upload cancelled by user");
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          md={6}
          sx={{ ...style, overflowY: "scroll", maxHeight: "80%" }}
        >
          <Box
            mb={2}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h6">Upload File</Typography>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Box>
          <CardContent>
            <Box>
              <form onSubmit={fileUploadSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Box
                    sx={{
                      border: "1px dashed #b6bed1",
                      borderRadius: "4px",
                      minHeight: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#f0f2f7",
                      color: "#8194aa",
                    }}
                  >
                    <Input
                      type="file"
                      id="fileupload"
                      name="files[]"
                      inputProps={{ multiple: true, accept: "image/*,.pdf" }}
                      onChange={inputChange}
                      sx={{ display: "none" }}
                    />
                    <Typography>
                      <label
                        htmlFor="fileupload"
                        style={{
                          color: "#475f7b",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Choose your files
                      </label>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mb: 3 }}>
                  {uploadProgress > 0 && uploadProgress < 100 && (
                    <Box display={"flex"} justifyContent={"center"}>
                      <CircularProgress />
                    </Box>
                  )}
                  {uploadProgress === 100 &&
                    selectedFile.length > 0 &&
                    selectedFile.map((data) => {
                      const { id, filename, fileimage, datetime, filesize } =
                        data;
                      return (
                        <Box
                          key={id}
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Box sx={{ mr: 2 }}>
                            {filename && isImageFile(filename) ? (
                              <img
                                src={fileimage}
                                alt=""
                                style={{ width: 40, height: 40 }}
                              />
                            ) : (
                              <DescriptionIcon
                                sx={{ fontSize: 40, color: "#999999" }}
                              />
                            )}
                          </Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h6" sx={{ fontSize: "16px" }}>
                              {filename}
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              Size: {filesize}{" "}
                              <span style={{ marginLeft: 16 }}>
                                Modified Time: {datetime}
                              </span>
                            </Typography>
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <TextField
                                label="Description"
                                fullWidth
                                variant="standard"
                                onChange={(e) => handleFileDescription(e, id)}
                              />
                              <IconButton onClick={() => removeFile(id)}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
                <Box display={"flex"} justifyContent={"flex-end"} columnGap={2}>
                  <Button
                    onClick={() => cancelUpload()}
                    disabled={!isUploading}
                  >
                    Cancel
                  </Button>
                  <UploadButton
                    isUploading={isUploading}
                    uploadProgress={uploadProgress}
                    noFileSelected={selectedFile.length === 0}
                  />
                </Box>
              </form>
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Modal>
  );
};
function UploadButton(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ m: 1, position: "relative" }}>
        <Button
          variant="contained"
          type="submit"
          disabled={
            props.isUploading ||
            props.uploadProgress < 100 ||
            props.noFileSelected
          }
        >
          Upload
        </Button>
        {props.isUploading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
    </Box>
  );
}
export default CreateFile;
