import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	MenuItem,
	useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IntranetContext } from './IntranetContext';
import { useContext } from 'react';
import config from '../../config';

const apiUrl = config.apiUrl;

const AddFolderDialog = ({ open, setOpenAddFolderDialog, mode = 'create', selectedFolderName = '', selectedfolderType = '', selectedFolderId='' }) => {
	const { linksFolders, setFileFolders, fileFolders, setLinksFolders } = useContext(IntranetContext)
	const [folderName, setFolderName] = useState(selectedFolderName);
	const [folderType, setFolderType] = useState(selectedfolderType);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const handleSubmit = async () => {
		let data = {
			name: folderName,
			folder_type: folderType
		};
		const formData = new FormData();
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				formData.append(key, data[key]);
			}
		}

		let folderSumbitUrl = mode === 'create' ? `${apiUrl}?section=intranet&action=createFolder` : `${apiUrl}?section=intranet&action=createFolder&id=${selectedFolderId}`
		try {
			const response = await fetch(
				folderSumbitUrl,
				{
					method: 'POST',
					body: formData
				}
			);

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await response.json();
			const newFolder = {
				folder_id: data.id,
				folder_name: data.name,
				folder_display_order: null,
			};
			if (mode === 'create') {
				if (data.folder_type === '1') {
					newFolder.links = [];
					setLinksFolders([...linksFolders, newFolder]);
				} else if (data.folder_type === '2') {
					newFolder.files = [];
					setFileFolders([...fileFolders, newFolder]);
				}
			} else if (mode === 'edit') {
				if (data.folder_type === '1') {
				 	let editedFolders = linksFolders.map(folder => {
						if (folder.folder_id === selectedFolderId) {
							return {
								folder_id: selectedFolderId,
								folder_name: folderName,
								folder_display_order: folder.folder_display_order, 
								links: folder.links 
							};
						}
						return folder;
					})
					setLinksFolders(editedFolders);
				} else if (data.folder_type === '2') {
					let editedFolders = fileFolders.map(folder => {
						if (folder.folder_id === selectedFolderId) {
							return {
								folder_id: selectedFolderId,
								folder_name: folderName,
								folder_display_order: folder.folder_display_order,
								files: folder.files
							};
						}
						return folder;
					})
					setFileFolders(editedFolders);
				}
			}
			handleClose();

			
		} catch (error) {
			console.error('Error adding folder:', error);
		}
	};
	const handleClose = ()=> {
		setFolderName('');
		setFolderType('');
		setOpenAddFolderDialog(false);
	};

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="sm">
			<DialogTitle>Add a New Folder</DialogTitle>
			<DialogContent>
				<TextField
					label="Folder Name"
					fullWidth
					value={folderName}
					onChange={(e) => setFolderName(e.target.value)}
					margin="dense"
					variant="outlined"
				/>
				<TextField
					label="Folder Type"
					select
					fullWidth
					value={folderType}
					onChange={(e) => setFolderType(e.target.value)}
					margin="dense"
					disabled={mode === 'edit'}
					variant="outlined"
				>
					<MenuItem value="1">Link</MenuItem>
					<MenuItem value="2">File</MenuItem>
				</TextField>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Cancel
				</Button>
				<Button onClick={handleSubmit} color="primary">
					Speichern
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddFolderDialog;
