import React from 'react';
import { Typography } from '@mui/material';

const DataNotAvailable = ({children}) => {
	return (
		<Typography sx={{ color: 'lightgray', padding: '5px 8px', border: '3px dashed lightgray', margin: '7px 0px' }}>
			{children}
		</Typography>
	);
};

export default DataNotAvailable;
