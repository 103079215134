import React, { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { Button, Box, Dialog, DialogTitle, DialogContent } from '@mui/material';

const PreviewFile = ({ imgApiUrl }) => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const fileName = record?.name;
  const fileUrl = `${imgApiUrl}/${record.project_id}/${fileName}`;

  const handleClose = () => {
    setOpen(false);
  };
  const isImageFile = (fileName) => {
    const imageExtensions = ['jpg', 'jpeg', 'png'];
    const extension = fileName.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
  };
  if (!record || !record.orignal_name) {
    return null;
  }
  return (
    <>
      <Button onClick={() => setOpen(true)}>Preview</Button>
      <Dialog
        open={open}
        fullScreen
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Preview File</DialogTitle>
        <DialogContent dividers width={"100%"} height={"100%"}>
          {isImageFile(fileName) ? (
            <Box display="flex" justifyContent="center" sx={{ minWidth: '300px', minHeight: '300px' }}>
              <img src={fileUrl} alt={record.orignal_name} title="File" style={{ maxWidth: '100%', objectFit: 'contain', maxHeight: 'auto' }} />
            </Box>
          ) : (
            <embed src={fileUrl} width="100%" height="100%" type="application/pdf"
            ></embed>
          )}
        </DialogContent>
        <Button onClick={handleClose}>Close</Button>
      </Dialog>
    </>
  );
};

export default PreviewFile;
