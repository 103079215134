import * as React from "react";
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  PasswordInput,
  UrlField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  Create,
  required,
  CloneButton,
  ListButton,
  TopToolbar,
  usePermissions,
  Pagination,
} from "react-admin";
import AdminField from "./AdminField";
import PassField from "./PassField";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import RegionButton from "./RegionButton";

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />
);

export const RegionList = () => {
  const { permissions } = usePermissions();
  return (
    <List perPage={1000} pagination={<ProjectPagination />}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <RegionButton source="id" />
        {permissions.access === "super" || permissions.access === "admin" ? (
          <EditButton />
        ) : null}
      </Datagrid>
    </List>
  );
};

const ProjectEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="< Zurück" />
  </TopToolbar>
);

export const RegionEdit = (props) => (
  <Edit title="Edit Region" actions={<ProjectEditActions />}>
    <SimpleForm>
      <TextInput readOnly source="id" />
      <TextInput label="Region Name" source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);

export const RegionCreate = (props) => (
  <Create title="Add New Region" {...props} actions={<ProjectEditActions />}>
    <SimpleForm>
      <TextInput label="Region Name" source="name" validate={required()} />
    </SimpleForm>
  </Create>
);
