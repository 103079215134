import React, { useState, useEffect } from 'react';
import IntranetNoteItem from './IntranetNoteItem';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import DataNotAvailable from './DataNotAvailable';
import { IntranetContext } from './IntranetContext';
import { DragOverlay } from '@dnd-kit/core';

export const IntranetNotes = ({ notes = [] }) => {
	const { activeNote, notesData } = React.useContext(IntranetContext);
	const [currentActiveNote, setCurrentActiveNote] = useState(null);
	useEffect(() => {
		if (activeNote && activeNote.id !== null) {
			const currentNote = notesData.flatMap(region => region.notes).find(note => note.note_id === activeNote.id);
			setCurrentActiveNote(currentNote);
		} else {
			setCurrentActiveNote(null);
		}
	}, [activeNote, notesData]);

	if (!notes || notes.length === 0) {
		return <DataNotAvailable>Keine Notiz hinzugefügt</DataNotAvailable>;
	}
	return (
		<>
			<SortableContext items={notes.map(note => note.id)} strategy={verticalListSortingStrategy}>
				{notes.map((note) => (
					<IntranetNoteItem 
						key={note.id} 
						id={note.note_id} 
						headline={note.note_headline} 
						text={note.note_text} 
						color={note.note_color}
						note={note}
					 />
				))}
			</SortableContext>
			{
				activeNote && (
					<>
						{activeNote.type === 'note' && (
							<DragOverlay>
								<IntranetNoteItem
									id={activeNote?.note_id}
									headline={currentActiveNote?.note_headline}
									text={currentActiveNote?.note_text}
									color={currentActiveNote?.note_color}
									note={currentActiveNote}
								/>
							</DragOverlay>
						)}
					</>
				)
			}
		</>
	);
};
