import React, { useEffect, useState } from 'react';
import { Button, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const ALLOWED_FILE_EXTENSIONS = [
  'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'csv',
];

const PreviewFile = ({ fileUrl, name, open, setOpen, handleDownloadFile }) => {
  const fileExtension = name.split('.').pop().toLowerCase();

  useEffect(() => {
    let timeoutId = null;
    if (ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
      // timeoutId = setTimeout(() => {
        // return <embed src={fileUrl} width="100%" height="100%" type="application/pdf" ></embed>
      // }, 500);
    }
    return () => {
      // clearTimeout(timeoutId);
      // if (ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
      //   handleClose();
      // }
    };
  }, [name, open]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleDownload = () => {
    handleDownloadFile();
  };
  // if (ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
  //   const timeoutId = null;
  //   setTimeout(() => {
  //      <div style= {{display: 'none'}}>
  //     return ( 
  //       <embed src={fileUrl} width="" height='' type="application/pdf" ></embed>
  //       );
  //     // </div>
  //   }, 500);
  // }

  return (
    <>
      <Dialog
        open={open}
        fullScreen
        onClose={handleClose}
        scroll="paper"
        c
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Preview File</DialogTitle>
        <Box display={'flex'} columnGap={1} sx={(theme) => ({
          position: 'absolute',
          right: 10,
          top: 8,
          color: theme.palette.grey[500],
        })}>

        <IconButton
          onClick={handleDownload}
        >
            <FileDownloadIcon />
        </IconButton>
        <IconButton
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        </Box>
        <DialogContent dividers width={"100%"} height={"100%"}>
          {fileExtension === 'txt' || fileExtension === 'pdf' ? 
           <embed src={fileUrl} width="100%" height="100%" type="application/pdf" ></embed> 
          : <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <h1 style={{color: 'gray'}}>File cannot be previewed.</h1> 
        </div>}
        </DialogContent>
        <DialogActions>
           <Button variant='contained' color="error" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PreviewFile;
